/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

/* line 2, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
audio:not([controls]) {
  display: none; }

/* line 4, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
[hidden] {
  display: none; }

/* line 6, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

/* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

/* line 10, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
a {
  text-decoration: none; }

/* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
a:focus {
  outline: none; }

/* line 12, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
a:hover, a:active {
  outline: 0; }

/* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
abbr[title] {
  border-bottom: 1px dotted; }

/* line 14, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
b, strong {
  font-weight: bold; }

/* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
blockquote {
  margin: 1em 40px; }

/* line 16, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
dfn {
  font-style: italic; }

/* line 17, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/* line 18, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

/* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

/* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/* line 23, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
q {
  quotes: none; }

/* line 24, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
q:before, q:after {
  content: "";
  content: none; }

/* line 25, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
small {
  font-size: 85%; }

/* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 27, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
sup {
  top: -0.5em; }

/* line 28, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
sub {
  bottom: -0.25em; }

/* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0; }

/* line 37, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
ul, ol {
  margin: 0;
  padding: 0 0 0 0px;
  display: block;
  list-style: none; }

/* line 38, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
dd {
  margin: 0 0 0 40px; }

/* line 39, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; 
  text-align:center;
}

/* line 41, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

/* line 42, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
svg:not(:root) {
  overflow: hidden; }

/* line 43, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
figure {
  margin: 0; }

/* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
form {
  margin: 0; }

/* line 46, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/* line 48, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
label {
  cursor: pointer; }

/* line 49, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

/* line 50, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
button, input {
  line-height: normal; }

/* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

/* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
button[disabled], input[disabled] {
  cursor: default; }

/* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

/* line 55, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/* line 56, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 57, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
input:invalid, textarea:invalid {
  background-color: #f0dddd; }

/* line 61, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
input {
  background: none;
  outline: none;
  border: none; }

/* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
td {
  vertical-align: top; }

/* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-top.scss */
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

@font-face {
  font-family: 'icons';
  src: url('  //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/atlantic-icons.eot?v=146730855618391485731462307371  ');
  src: url(' //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/atlantic-icons.eot?v=146730855618391485731462307371 ?#iefix ') format("embedded-opentype"), url('  //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/atlantic-icons.woff?v=61782330397989990281462307371  ') format("woff"), url('  //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/atlantic-icons.ttf?v=74661228507603658381462307371  ') format("truetype"), url('  //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/atlantic-icons.svg?v=26180172584998422491462307371 #atlantic-icons ') format("svg");
  font-weight: normal;
  font-style: normal; }

/*  */
/* ATLANTIC by PIXEL UNION */
/* ----------------------------- */
/* General */
/* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
a {
  color: #b3b3b3; }

/* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
a:hover {
  color: #222222; }

/* line 38, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* line 39, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
input::-ms-clear {
  display: block; }

/* line 41, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
input[type="text"], input[type="email"], input[type="number"], input[type="password"], textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px; }
  /* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
  input[type="text"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color:  #bbbbbb ; }
  /* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
  input[type="text"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="number"]:-moz-placeholder, input[type="password"]:-moz-placeholder, textarea:-moz-placeholder {
    color:  #bbbbbb ; }
  /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
  input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="password"]::-moz-placeholder, textarea::-moz-placeholder {
    color:  #bbbbbb ; }
  /* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
  input[type="text"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color:  #bbbbbb ; }

/* line 47, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
.lt-ie9 input[type="password"] {
  font-family: Arial, sans-serif !important;
  /* Opens Sans has known bug with passwords fields in IE8 */ }

/* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
textarea {
  background-color: #ffffff; }

/* line 55, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/style.css.liquid.scss */
body {
  color:  #ababab ;
  font-family:  'Source Sans Pro', sans-serif ;
  background-color: #ffffff; }

/* Variables */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.uc-title, .main-header-wrap .main-header .store-title, .main-header-wrap .main-header .mini-cart .brand, .main-header nav.full .nav-item > .label, .mega-nav .list .label, .mobile-dropdown .list .list-item a, .customer .header-link, .customer.account .title, .customer.order .order-history .table td.product-item .label.vendor, .collections .collection .browse, .featured-collections .collection .browse, .sidebar .module > label, .sidebar .recent-posts .date, .sidebar .tags-list .tag .count, .sidebar .twitter .date, .product .overlay .label, .product .brand, .quick-shop-content .brand, #product-area .brand, .mobile-product-title .brand, .template-index .slide .content .call-to-action, .template-index .article .date, .template-blog .articles .meta li label, .template-article .articles .meta li label, .template-article .articles .comments-wrap .title, .template-article .articles .comments-wrap.read .comment .date, #product-area .details .description .go-to-product, .quick-shop-content .details .description .go-to-product, .template-page .sidebar .title, .template-contact .sidebar .title, .template-cart .table td.product-item .label.vendor, .template-search .result.item-article .date, .template-search .result.item-product .details .brand, .main-footer .connect .title {
  font-family:  'Source Sans Pro', sans-serif ;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 10, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.pxuSexyDropWrapper {
  text-align: left;
  width: 235px;
  background-color: #f9f9f9;
  -webkit-box-shadow: 0px 0px 0px 1px  #ffffff ;
  -moz-box-shadow: 0px 0px 0px 1px  #ffffff ;
  box-shadow: 0px 0px 0px 1px  #ffffff ;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline; }
  /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
  .lt-ie9 .pxuSexyDropWrapper {
    border: 1px solid  #ffffff ; }
  /* line 22, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .pxuSexyDropWrapper, .pxuSexyDropWrapper select {
    cursor: pointer; 
	font-size: 12px;
}

.pxuSexyDropWrapper, .pxuSexyDropWrapper select option {
font-family: 'PT Serif','Times New Roman',Times,serif ;
  color: #757575;
}

  /* line 24, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .pxuSexyDropWrapper select {
    left: 10px; }
  /* line 28, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .pxuSexyDropWrapper .pxuSexyDrop {
    color: #757575;
    font-size: 12px;
    padding: 8px 10px;
    font-family:  'Source Sans Pro', sans-serif ; }
  /* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .pxuSexyDropWrapper .toggle {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 38px;
    background:  #ffffff ;
    -webkit-border-radius: 0px 4px 4px 0px;
    -moz-border-radius: 0px 4px 4px 0px;
    -ms-border-radius: 0px 4px 4px 0px;
    -o-border-radius: 0px 4px 4px 0px;
    border-radius: 0px 4px 4px 0px; }
    /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .pxuSexyDropWrapper .toggle:before {
      content: '';
      display: block;
      position: absolute;
      top: 14px;
      right: 14px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color:  #757575  transparent transparent transparent; }
    /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .lt-ie9 .pxuSexyDropWrapper .toggle:before {
      display: none; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.empty {
  font-size: 16px;
  margin: 40px 0 60px;
  font-style: italic; }
  @media (max-width: 719px) {
    /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .empty {
      width: 100%;
      text-align: center;
      padding: 0 30px; } }
  /* line 77, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .empty a {
    color:  #b3b3b3 ; }
    /* line 79, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .empty a:hover {
      color:  #222222 ; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.error-message {
  font-family: 'Opens Sans', sans-serif;
  background-color: #FBEDEC;
  color: #ee575a;
  font-size: 12px;
  border: 1px solid #EA555C;
  height: 42px;
  width: 75%; }
  @media (max-width: 719px) {
    /* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .error-message {
      width: 100%; } }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.content-area {
  margin: 0 auto;
  padding: 0 15px;
  min-width: 690px;
  max-width: 1030px;
  
  width: 84%; margin-bottom:2px;}
  @media (max-width: 719px) {
    /* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .content-area {
      min-width: 290px;
      width: 100%; } }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 113, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.section-title {
  font-family:  'Source Sans Pro', sans-serif ;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 45px;
  padding: 45px 0 0;
  color:  #666666 ; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 127, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.section-border {
  border-top: 1px solid  #ffffff ; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 133, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.action-button {
  font-family:  'Source Sans Pro', sans-serif ;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color:  #ffffff ;
  background-color:  #d7ca9d !important ;
  padding: 9px 14px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline; }
  /* line 146, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .action-button:hover {
    background-color:  #222222 ;
    color:  white ; }
  /* line 151, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .action-button.desaturated {
    background-color:  #ffffff ; }
  /* line 152, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .action-button.desaturated:hover {
    background-color:  #d7c99d ; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 157, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.field {
  border: 1px solid  #ffffff ;
  color:  #ababab ;
  font-family:  'Source Sans Pro', sans-serif ;
  outline: none;
  font-size: 12px;
  padding: 8px 12px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  -webkit-transition: box-shadow 250ms, border 250ms, color 250ms;
  -moz-transition: box-shadow 250ms, border 250ms, color 250ms;
  -o-transition: box-shadow 250ms, border 250ms, color 250ms;
  transition: box-shadow 250ms, border 250ms, color 250ms; }
  /* line 167, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .field.error {
    border: 1px solid #F3AAAD;
    color: #ee575a;
    -webkit-box-shadow: 0px 0px 7px rgba(238, 87, 90, 0.4);
    -moz-box-shadow: 0px 0px 7px rgba(238, 87, 90, 0.4);
    box-shadow: 0px 0px 7px rgba(238, 87, 90, 0.4); }
    /* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
    .field.error::-webkit-input-placeholder {
      color: #ee575a; }
    /* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
    .field.error:-moz-placeholder {
      color: #ee575a; }
    /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
    .field.error::-moz-placeholder {
      color: #ee575a; }
    /* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
    .field.error:-ms-input-placeholder {
      color: #ee575a; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 177, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.page-title {
  padding: 16px 0 35px;
  border-bottom: 1px solid  #ffffff ; }
  @media (max-width: 719px) {
    /* line 177, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .page-title {
      padding: 35px 0;
      text-align: center;
      border-bottom: none; } }
  /* line 187, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .page-title .label {
    font-size: 18px;
    letter-spacing:1px;
    font-size:   ;
    font-weight: 700;
    font-family:  'Source Sans Pro', sans-serif ;
    text-transform:   ;
    color:  #666666 ; }
    /* line 195, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
    .page-title .label a {
      color:  #666666 ; }
    @media (max-width: 719px) {
      /* line 187, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
      .page-title .label {
        display: block;
        white-space: pre-line; } }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 203, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
.load-up {
  padding: 0 30px;
  font-size: 14px;
  color: #757575; }
  /* line 208, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_common-classes.scss */
  .featured-collections .load-up {
    padding-bottom: 65px; }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
.rte {
  overflow: hidden;
  font-size: 14px; }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte p, .rte blockquote, .rte h1, .rte h2, .rte ul, .rte ol {
    margin: 0 0 15px; }
  /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte p, .rte blockquote, .rte li, .rte a {
    font-size: 14px;
    line-height: 22px; }
  /* line 12, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte > table:first-child {
    margin-top: 0px; }
  /* line 16, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte > p:first-child .image-wrap {
    margin-top: 6px; }
  /* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte ul, .rte ol {
    padding-left: 20px; }
  /* line 24, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte ul {
    list-style-type: disc; }
  /* line 25, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte ol {
    list-style-type: decimal; }
  /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte li {
    margin: 8px 0; }
  /* line 28, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte blockquote {
    max-width: 70%;
    font-style: italic;
    color:  #757575 ;
    padding-left: 20px;
    margin-left: 0;
    border-left: 2px solid  #eeefea ; }
  /* line 37, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte img {
    max-width: 100%; }
  /* line 41, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte iframe, .rte object, .rte embed {
    visibility: hidden;
    border: 0;
    outline: 0; }
  /* line 47, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte a {
    color:  #b3b3b3 ; }
    /* line 49, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte a:hover {
      color:  #222222 ; }
  /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6 {
    color:  #666666 ;
    font-weight: 7000; }
  /* line 57, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h1 {
    font-size: 28px; }
  /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h2 {
    font-size: 21px; }
  /* line 59, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h3 {
    font-size: 18px;
    margin: 0 0 18px; }
  /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h4 {
    font-size: 16px;
    margin: 0 0 16px; }
  /* line 61, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h5 {
    font-size: 14px;
    margin: 0 0 14px; }
  /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte h6 {
    font-size: 12px;
    margin: 0 0 12px; }
  /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte hr {
    width: 100%;
    height: 1px;
    margin: 40px 0;
    background-color:  #eeefea ;
    outline: none;
    border: none; }
    @media (max-width: 719px) {
      /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
      .rte hr {
        margin: 20px 0; } }
  /* line 75, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte .caption {
    font-style: italic;
    font-size: 12px;
    color: #757575;
    margin-top: -10px; }
  /* line 82, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte .image-wrap {
    max-width: 100%;
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
    /* line 88, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte .image-wrap img {
      position: relative;
      z-index: -2; }
  /* line 94, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte table {
    margin: 30px 0;
    width: 100%;
  }
    /* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte table td {
      border: 1px solid #eeefea;
      padding: 23px 25px 22px; }
    /* line 104, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte table thead td {
      background-color: white}
  /* line 109, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte .tabs {
    display: block;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid  #ffffff ;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap; }
    /* line 119, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte .tabs li {
      width: auto;
      height: 40px;
      padding: 0;
      margin: 0;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
      /* line 126, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
      .rte .tabs li a {
        display: block;
        height: 41px;
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 14px;
        line-height: 42px;
        padding: 0 16px;
        color: #b3b3b3;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent; }
        /* line 138, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
        .rte .tabs li a:hover {
          color:  #666666 ; }
      /* line 141, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
      .rte .tabs li.active a {
        color: #ababab;
        border-top: 1px solid  #ffffff ;
        border-left: 1px solid  #ffffff ;
        border-right: 1px solid  #ffffff ;
        background-color: #ffffff;
        height: 42px;
        -moz-border-radius-topleft: 4px;
        -webkit-border-top-left-radius: 4px;
        border-top-left-radius: 4px;
        -moz-border-radius-topright: 4px;
        -webkit-border-top-right-radius: 4px;
        border-top-right-radius: 4px; }
  /* line 156, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
  .rte .tabs-content {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden; }
    /* line 166, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
    .rte .tabs-content > li {
      display: none;
      margin: 30px 0 20px; }
      /* line 169, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_rte.scss */
      .rte .tabs-content > li.active {
        display: block; }

/* Header */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
.main-header-wrap {
  position: relative;
  z-index: 5000; }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
  .main-header-wrap .main-header {
    padding: 0 15px;
    position: relative;
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
    /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
    .main-header-wrap .main-header .store-title {
      display: block;
      font-size: 28px;
      letter-spacing: 0.2em !important;
      max-width: 100%;
      margin: -25px 0 3px;text-align:center;border-bottom:0px solid #b9b9b9;
       }
      @media (min-width: 720px) and (max-width: 960px) {
        /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .store-title {
          font-size: 21px; } }
      @media (max-width: 719px) {
        /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .store-title {
          font-size: 23px;
          max-width: 100%;
          width: 100%;
          text-align: center;
          padding: 0px; } }
      /* line 30, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .store-title img {
        max-width: 100%; position:relative;margin:34px auto 34px;}
      /* line 34, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .store-title a {
        color:  #222222 ; }
        /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .store-title a:hover {
          color:  #222222 ; }
    /* line 43, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
    .main-header-wrap .main-header .action-links {
      position: relative;
      z-index: 2000; }
    /* line 48, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
    .main-header-wrap .main-header .tools {
      position: absolute;height:44px;
      visibility: hidden;
      left:0;top:28px;border-bottom:1px solid #f5f5f5;
      max-width: 100%; width: 100%; }
      @media (max-width: 719px) {
        /* line 48, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools {
          display: none; } }
      /* line 55, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .tools > a, .main-header-wrap .main-header .tools > div {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px;
        color:  #c9c7c7 ;
        margin: 0 10px;
        position: relative; }
        /* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools > a:hover, .main-header-wrap .main-header .tools > a.active, .main-header-wrap .main-header .tools > div:hover, .main-header-wrap .main-header .tools > div.active {
          color:  #686868 ; }
        /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools > a.active, .main-header-wrap .main-header .tools > div.active {
          z-index: 5000; }
      /* line 67, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .tools .icon {
        font-family: 'icons';
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 0;
        top: 0px;
        line-height: 0px; }
      /* line 76, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .tools .search {
        padding-left: 24px; }
        /* line 78, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools .search .icon {
          font-size: 25px;
          top: 8px; }
      /* line 83, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .tools .mini-cart-wrap {
        right:0;padding-left:32px;
        position: absolute;
        cursor: pointer; }
        /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools .mini-cart-wrap .icon {
  background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/bag.png?v=12066636849047461511462307371 ) no-repeat top left;      
  font-size: 40px;top:-5px;top:-5px !important;
          width:19px;height:20px; display:block;float:left;}
      /* line 92, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .tools .account-options {
        padding-left: 25px;
        margin-right: 0px; }
        /* line 95, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .tools .account-options .icon {
          font-size: 27px;
          top: 7px; }
    /* line 105, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
    .main-header-wrap .main-header .mini-cart {
      cursor: auto;
      display: none;
      width: 328px;
      background: #ffffff;
      position: absolute;
      top: 35px;
      left: -243px;
      border: 1px solid #ffffff;
      /* -------------------------- */
      /* -------------------------- */
      /* -------------------------- */
      /* -------------------------- */
      /* -------------------------- */
      /* -------------------------- */ }
      @media (max-width: 719px) {
        /* line 105, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart {
          display: none; } }
      /* line 116, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart.account-enabled {
        left: -157px; }
        /* line 119, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart.account-enabled .arrow {
          left: 205px; }
      /* line 125, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart.empty-cart .no-items {
        display: block; }
      /* line 126, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart.empty-cart .options {
        display: none; }
      /* line 132, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .arrow, .main-header-wrap .main-header .mini-cart .arrow:before {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #ffffff;
        top: -8px;
        left: 294px;
        margin-left: -7px; }
        /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
        .lt-ie9 .main-header-wrap .main-header .mini-cart .arrow, .lt-ie9 .main-header-wrap .main-header .mini-cart .arrow:before {
          display: none; }
      /* line 146, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .arrow:before {
        content: '';
        display: block;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #ffffff;
        z-index: -1;
        left: 50%;
        position: absolute;
        margin-left: -16px;
        top: -1px; }
      /* line 161, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .no-items {
        display: none;
        text-align: center;
        margin: 30px 0 30px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-size: 14px; }
      /* line 172, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .item {
        width: 100%;
        border-bottom: 1px solid  #eeefea ; }
      /* line 179, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .image-wrap, .main-header-wrap .main-header .mini-cart .details {
        float: left;
        margin: 20px; }
      /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .image-wrap {
        position: relative;
        width: 97px; }
        /* line 188, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .image-wrap img {
          width: 100%; }
        /* line 190, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .image-wrap a {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0; }
          /* line 197, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
          .main-header-wrap .main-header .mini-cart .image-wrap a.overlay {
            -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
            box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
      /* line 203, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .details {
        margin-left: 0px;
        width: 150px; }
      /* line 208, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .brand {
        font-size: 10px;display:none;
        margin: 0px 0px 3px 0px; }
        /* line 213, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .brand a {
          color:  #ababab ; }
      /* line 215, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .title {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 14px;
        margin: 0px 0px 7px 0px; }
        /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .title span {
          color:  #bbbbbb ; }
          /* line 222, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
          .main-header-wrap .main-header .mini-cart .title span.quantity {
            margin-left: 4px; }
        /* line 225, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .title a {
          color:  #b3b3b3 ; }
      /* line 229, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .price {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px;
        color: ;
        margin: 0px 0px 6px 0px; }
      /* line 235, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .variant {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 11px;
        color:  #ababab ;
        margin: 0px 0px 0px 0px; }
      /* line 244, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .mini-cart .options {
        padding: 20px; }
        /* line 247, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .options .action-button {
          width: 133px;
          text-align: center; }
        /* line 252, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .options .view-cart {
          float: left; }
        /* line 256, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .mini-cart .options .checkout {
          float: right; }
    /* line 266, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
    .main-header-wrap .main-header .search-wrap {
      display: none;
      width: 25%;
      z-index: 2500;
      background-color: #ffffff;
      border: 1px solid  #ffffff ;
      position: absolute;
      top: 0px;
      right: 14px;
      -webkit-border-radius: 31px;
      -moz-border-radius: 31px;
      -ms-border-radius: 31px;
      -o-border-radius: 31px;
      border-radius: 31px; }
      @media (max-width: 719px) {
        /* line 266, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
        .main-header-wrap .main-header .search-wrap {
          display: none; } }
      /* line 279, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .search-wrap form {
        display: block;
        font-size: 0px; }
      /* line 284, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .search-wrap input {
        display: block;
        font-size: 12px;
        width: 100%;
        padding: 8px 38px 8px 12px;
        font-family: 'Source Sans Pro', sans-serif; }
        /* line 68, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
        .ie10 .main-header-wrap .main-header .search-wrap input {
          padding-top: 9px; }
      /* line 294, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
      .main-header-wrap .main-header .search-wrap .icon {
        position: absolute;
        top: 50%;
        right: 14px;
        height: 24px;
        width: 15px;
        margin-top: -12px;
        font-family: 'icons';
        -webkit-font-smoothing: antialiased;
        font-size: 25px;
        line-height: 26px;
        color:  #ffffff ; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* line 314, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_header.scss */
  .main-header-wrap .main-header .search-wrap .icon {
    line-height: 24px; } }
/* General navigation */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav {
  border-top: 1px solid  #ffffff ; }
  /* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.bordered {
    border-bottom: 1px solid #ffffff; }
  /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full {
    display: block; }
    @media (max-width: 719px) {
      /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full {
        display: none; } }
  /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.compact {
    display: none; }
    @media (max-width: 719px) {
      /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.compact {
        display: block; } }

/* Full navigation */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 25, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full {
  position: relative;
  z-index: 1000; }
  /* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full.multi-line {
    text-align: center; }
    /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full.multi-line:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #ffffff; }
  /* line 46, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full.compress .nav-item > .label {
    font-size: 11px;
    padding: 25px 15px; }
  /* line 50, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full.compress .nav-item.dropdown > a, .main-header nav.full.compress .nav-item.has-mega-nav > a {
    padding-right: 30px; }
    /* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full.compress .nav-item.dropdown > a .icon, .main-header nav.full.compress .nav-item.has-mega-nav > a .icon {
      right: 12px;
      line-height: 14px; }
  /* line 61, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full .nav-item {
    position: relative;
    text-align: left;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    /* Default nav item */
    /* -------------------------- */
    /* Dropdown menu item */
    /* -------------------------- */
    /* Tiered dropdown */
    /* -------------------------- */ }
    /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item > .label {
      display: block;
      font-size: 14px;
      padding: 25px 50px 25px 50px;
      position: relative;
      color:  #2d2d2d ; }
      /* line 77, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item > .label:before {
        content: '';
        position: absolute;
        top: 16px;
        bottom: 16px;
        right: 0px;
        width: 1px;
        background-color: #eeefea; }
      /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item > .label:hover {
        color:  #686868 ; }
    /* line 92, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item.last a:before, .main-header nav.full .nav-item.hide-divider a:before {
      display: none; }
    /* line 97, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item.dropdown {
      position: relative; }
    /* line 98, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item.dropdown > a, .main-header nav.full .nav-item.has-mega-nav > a {
      padding-right: 40px; }
    /* line 105, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item.dropdown:hover, .main-header nav.full .nav-item.has-mega-nav.active {
      background-color: #ffffff;
      /* Attn: Reveals the dropdown list */ }
      /* line 108, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item.dropdown:hover > .label, .main-header nav.full .nav-item.has-mega-nav.active > .label {
        color: #000000; }
        /* line 110, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        .main-header nav.full .nav-item.dropdown:hover > .label:before, .main-header nav.full .nav-item.has-mega-nav.active > .label:before {
          display: none; }
      /* line 113, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item.dropdown:hover > .dropdown-wrap, .main-header nav.full .nav-item.has-mega-nav.active > .dropdown-wrap {
        display: block; }
    /* line 117, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item .icon {
      font-family: 'icons';
      font-size: 16px;
      position: absolute;
      right: 22px;
      top: 25px;
      height: 12px;
      width: 12px;
      line-height: 15px; }
      /* line 68, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
      .ie10 .main-header nav.full .nav-item .icon {
        top: 27px; }
      /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
      .lt-ie9 .main-header nav.full .nav-item .icon {
        top: 27px; }
    /* line 131, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .main-header nav.full .nav-item .dropdown-wrap {
      display: none;
      position: absolute;
      z-index: 1000;
      top: 100%;
      left: 0;
      width: 198px;
  	  text-transform:uppercase;
      border: 1px solid #ffffff;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background-color: rgba(255, 255, 255, 0.9);
      //background-color: #ffffff; 
      }
      /* line 142, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap.child.right {
        left: auto;
        right: -198px;
        top: 0; }
      /* line 148, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap.child.left {
        left: -198px;
        top: 0; }
      /* line 153, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item {
        position: relative; }
        /* line 156, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover {
          /* Attn: Reveals child dropdown lists */ }
          /* line 157, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
          .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > a .label, .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > a .icon {
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
            opacity: 1; }
          /* line 158, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
          .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > .dropdown-wrap {
            display: block; }
        /* line 161, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item .icon {
          right: 16px;
          top: 26px;
          font-size: 18px; }
      /* line 168, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a {
        display: block;
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px;
        //padding: 23px 42px 23px 26px;
        padding: 15px 0px;
        border-top: 1px solid #ffffff;
        border-top: 1px solid rgba(255, 255, 255, 0.15); }
        /* line 176, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .label, .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .icon {
          color: #000000;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
          opacity: 0.7;
          background-color:rgba(255,255,255,09.);
          //background-color: #ffffff; 
        }
      /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item.first > a {
        border-top: 0px; }
      /* line 187, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item.first > .dropdown-wrap {
        top: -1px; }

/* Compact navigation */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 202, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
nav.compact {
  position: relative; }
  /* line 205, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  nav.compact .nav-item {
    position: relative;
    height: 55px;
    width: 33.33333%;
    font-family: 'icons';
    -webkit-font-smoothing: antialiased;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    /* Default menu item */
    /* -------------------------- */
    /* Dropdown menu item */
    /* -------------------------- */ }
    /* line 213, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.dropdown .icon {
      font-size: 27px;
      margin-top: 8px; }
    /* line 217, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.account .icon {
      font-size: 27px;
      margin-top: 8px; }
    /* line 221, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.cart .icon {
      font-size: 41px;
      margin-top: 14px;margin-left:-5px;background:url(//cdn.shopify.com/s/files/1/1247/5491/t/5/assets/bag.png?v=12066636849047461511462307371) no-repeat top center; display:block;height:25px; }
    /* line 225, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.search .icon {
      font-size: 27px;
      margin-top: 8px; }
    /* line 231, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.cart .count {
      display: block;
      position: absolute;
      top: 14px;
      left: 50%;
      padding: 3px 3px 0px 3px;
      line-height: 10px;
      margin-left: 4px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
      border-radius: 12px;
      background-color:  #c9c7c7 ;
      border: 1px solid #ffffff; }
      /* line 243, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item.cart .count span {
        position: relative;
        color: #ffffff;
        font-family: 'Helvetica Neue';
        font-size: 10px;
        margin: 0;
        padding: 0;
        top: -2px; }
    /* line 258, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item > a, nav.compact .nav-item > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color:  #c9c7c7 ;
      cursor: pointer; }
      /* line 267, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item > a span, nav.compact .nav-item > div span {
        display: block;
        margin-top: 15px;
        text-align: center; }
      /* line 273, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item > a:before, nav.compact .nav-item > div:before {
        content: '';
        position: absolute;
        top: 11px;
        bottom: 11px;
        right: 0px;
        width: 1px;
        background-color:  #eeefea ; }
    /* line 284, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.last a:before, nav.compact .nav-item.last div:before {
      display: none; }
    /* line 286, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item.active {
      background-color: #ffffff; }
      /* line 289, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item.active > a, nav.compact .nav-item.active > div {
        color: #000000; }
        /* line 291, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        nav.compact .nav-item.active > a:before, nav.compact .nav-item.active > div:before {
          display: none; }
      /* line 294, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item.active .arrow {
        background-color: #ffffff; }
    /* line 300, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .nav-item .dropdown-wrap {
      display: none; }
      /* line 303, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      nav.compact .nav-item .dropdown-wrap.top {
        position: absolute;
        top: 100%;
        left: 0; }
  /* line 313, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  nav.compact.account-enabled .nav-item {
    width: 25%; }
  /* line 318, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  nav.compact .search-outer-wrap {
    display: none;
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: 0;
    right: 0;
    background: transparent; }
    /* line 327, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    nav.compact .search-outer-wrap .search-wrap {
      display: block;
      top: 50%;
      left: 8%;
      right: 8%;
      width: auto; }

/* Mega nav */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 340, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mega-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  z-index: 1500;
  margin-top: 1px;
  overflow: hidden;
  background:rgba(255,255,255,0.9);
  
  /* Default columned list */
  /* -------------------------- */
  /* Expanded and category lists */
  /* -------------------------- */ }
  /* line 351, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mega-nav .mega-nav-wrap {
    padding: 0 15px;
    position: relative; }
  /* line 356, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mega-nav .back {
    display: none;
    position: absolute;
    top: 47px;
    left: 50%;
    margin-left: -2px;
    width: 0;
    height: 0;
    z-index: 5000;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 1px solid #ffffff;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7; }
    /* line 371, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .back:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  /* line 377, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mega-nav .list {
    padding-left: 30px;
    margin-top: 34px;
    margin-bottom: 24px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
  	width: 16%;
    zoom: 1;
    *display: inline; }
    /* line 384, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list.first {
      padding-left: 0; }
    /* line 385, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list.one-col {
      width: 100%; }
      /* line 387, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list.one-col .list-item {
        width: 150px; }
    /* line 391, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list.two-col {
      width: 50%; }
    /* line 392, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list.three-col {
      width: 33.33333%; }
.mega-nav .list.five-col {
      width: 20%; }
    /* line 394, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list .label {
      font-size: 14px;
      color: #000000;
      width: 100%;
      margin: 0 0 10px;
      padding: 10px 0 14px;
       }
      /* line 403, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list .label a {
        color: #000000; }
    /* line 406, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .list .list-item {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      *vertical-align: auto;
      zoom: 1;
  text-transform:uppercase;
      *display: inline;
      width: 50%;
      margin: 0 -4px 2px 0;
      padding-right: 10px; }
      /* line 412, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list .list-item.one-column {
        width: 100%; }
      /* line 416, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list .list-item a {
        font-size: 12px;
        color: #000000;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
        opacity: 0.7;
        }
        /* line 422, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
        .mega-nav .list .list-item a:hover {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
          opacity: 1; }
      /* line 425, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list .list-item a.show-more {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      /* line 429, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .list .list-item .more-icon {
        margin-left: 6px;
        font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 100;
        position: relative;
        top: -1px; }
  /* line 444, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mega-nav .expanded-list, .mega-nav .category-list {
    display: none;
    position: relative; }
    /* line 448, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .expanded-list .list, .mega-nav .category-list .list {
      display: none;
      width: 100%;
      margin-left: 0; }
      /* line 453, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
      .mega-nav .expanded-list .list.active, .mega-nav .category-list .list.active {
        display: block; }
    /* line 456, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mega-nav .expanded-list .list-item, .mega-nav .category-list .list-item {
      width: 150px; }

/* Mobile navigation */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 467, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mobile-dropdown {
  display: none;
  position: relative;
  z-index: 5000;
  /* Must be higher than the <header> element */
  margin-top: -2px;
  background-color: #ffffff; }
  @media (min-width: 720px) {
    /* line 467, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown {
      display: none !important; } }
  /* line 480, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown .list.primary {
    background-color: #ffffff; }
    /* line 483, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list.primary > .first {
      border-top: none;
      padding-top: 10px; }
  /* line 488, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown .list.secondary {
    display: none;
    background-color: rgb( 225, 225, 225 ); }
    /* line 492, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list.secondary .list-item a {
      background-color: rgb( 225, 225, 225 ); }
  /* line 494, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown .list.tertiary {
    display: none;
    background-color: rgb( 175, 175, 175); }
    /* line 498, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list.tertiary .list-item a {
      background-color: rgb( 175, 175, 175 ); }
  /* line 500, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown .list.quaternary {
    display: none;color:#000;color:#000 !important;
    background-color: rgb( 125, 125, 125); }
    /* line 504, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list.quaternary .list-item a {
      background-color: rgb( 125, 125, 125 ); }
  /* line 507, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown .list .list-item {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.05); }
    /* line 511, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list .list-item a {
      display: block;
      margin: 0 auto;
      padding: 19px 15px;
      width: 84%;
      min-width: 290px;
      font-size: 12px;
      color: #000000;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      position: relative; }
    /* line 525, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list .list-item .icon {
      position: absolute;
      top: 19px;
      right: 0;
      height: 15px;
      width: 15px;
      background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/main-sprite.png?v=93678888592050608781462307376 ) no-repeat -162px -17px;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7; }
    /* line 535, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
    .mobile-dropdown .list .list-item.expanded > a .icon {
      background-position: -192px -17px; }

/* Dark colour scheme */
/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 551, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full.dark .nav-item.dropdown:hover .label, .main-header nav.full.dark .nav-item.has-mega-nav.active .label {
  color: #000000; }
/* line 552, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full.dark .nav-item.dropdown:hover .arrow, .main-header nav.full.dark .nav-item.has-mega-nav.active .arrow {
  background-color: #ffffff; }
/* line 554, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full.dark .nav-item.dropdown:hover.has-mega-nav .border, .main-header nav.full.dark .nav-item.has-mega-nav.active.has-mega-nav .border {
  bottom: 0px; }
/* line 558, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full.dark .nav-item .dropdown-wrap {
  border: 1px solid rgba(0, 0, 0, 0.15); }
/* line 559, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.main-header nav.full.dark .nav-item .dropdown-item a {
  border-top: 1px solid rgba(0, 0, 0, 0.15); }
  /* line 561, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .main-header nav.full.dark .nav-item .dropdown-item a .label, .main-header nav.full.dark .nav-item .dropdown-item a .icon {
    color: #000000; }

/* line 571, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
nav.compact.dark .nav-item.active > a, nav.compact.dark .nav-item.active > div {
  color: #000000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7; }
/* line 575, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
nav.compact.dark .nav-item.active .arrow {
  background-color: #000000; }
/* line 576, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
nav.compact.dark .nav-item.active .border {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15); }

/* line 591, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mega-nav.dark .back {
  border-bottom-color: #000000; }
/* line 593, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mega-nav.dark .list .label {
  color: #000000;
  border-bottom-color: rgba(0, 0, 0, 0.15); }
  /* line 597, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mega-nav.dark .list .label a {
    color: #000000; }
/* line 599, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mega-nav.dark .list .list-item a {
  color: #000000; }

/* line 607, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
.mobile-dropdown.dark .list .list-item {
  border-top-color: rgba(0, 0, 0, 0.05); }
  /* line 609, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown.dark .list .list-item a {
    color: #000000; }
  /* line 610, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown.dark .list .list-item .icon {
    background-position: -162px -47px; }
  /* line 611, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_navigation.scss */
  .mobile-dropdown.dark .list .list-item.expanded > a .icon {
    background-position: -192px -47px; }


/* Account */
/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .empty {
  margin-top: 0px; }
/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .message {
  font-size: 14px;
  font-style: italic;
  color:  #757575 ;
  margin: 20px 0 50px; }
@media (max-width: 719px) {
  /* line 14, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .page-title {
    border-bottom: 1px solid #ffffff; } }
/* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .back-link {
  font-family:  'Source Sans Pro', sans-serif ;
  font-size: 12px;
  color:  #757575 ;
  margin-bottom: 20px;
  display: none; }
  @media (max-width: 719px) {
    /* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .back-link {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; } }
/* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .header-link {
  float: right;
  margin-top: 20px;
  font-size: 12px;
  color:  #757575 ; }
  @media (max-width: 719px) {
    /* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .header-link {
      float: none;
      margin-top: 25px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; } }
/* line 43, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .account-form {
  width: 38%;
  padding: 25px 0 100px; }
  @media (max-width: 719px) {
    /* line 43, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form {
      width: 100%;
      padding: 10px 0 55px;
      text-align: center; } }
  /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .account-form .error-message {
    margin: 0 0 25px;
    text-align: center;
    width: 100%; }
    @media (max-width: 719px) {
      /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer .account-form .error-message {
        margin-top: 25px; } }
    /* line 59, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form .error-message span {
      margin-top: 12px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
  /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .account-form .field-wrap {
    margin-top: 20px; }
    /* line 67, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form .field-wrap.author {
      margin-top: 0; }
    /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form .field-wrap label {
      cursor: auto;
      display: block;
      font-size: 14px;
      margin-bottom: 12px; }
      @media (max-width: 719px) {
        /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
        .customer .account-form .field-wrap label {
          text-align: left; } }
    /* line 77, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form .field-wrap .field {
      width: 100%; }
    /* line 81, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .account-form .field-wrap textarea {
      min-height: 210px; }
  /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .account-form .submit-button {
    margin-top: 30px;
    padding: 10px 14px 9px; }
    @media (max-width: 719px) {
      /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer .account-form .submit-button {
        width: 100%;
        margin-top: 35px; } }
  /* line 97, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .account-form .helper {
    margin: 0 0 8px 15px;
    font-size: 12px;
    font-family:  'Source Sans Pro', sans-serif ;
    color:  #757575 ;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: bottom;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
    @media (max-width: 719px) {
      /* line 97, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer .account-form .helper {
        margin: 30px 0 0; } }
/* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer .table {
  width: 100%;
   }
  /* line 115, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .table th {
    background: #f9f9f9;
    padding: 22px 0;
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
    /* line 122, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .table th.first {
      padding: 22px 0px 22px 30px; }
    @media (max-width: 719px) {
      /* line 115, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer .table th {
        display: none; } }
  /* line 127, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer .table td {
    padding: 32px 30px 32px 0;
    border-top: 1px solid  #ffffff ;
    font-size: 14px;
    vertical-align: middle;
    position: relative; }
    /* line 134, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .table td.first {
      padding: 32px 30px; }
    /* line 136, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer .table td:before {
      display: none;
      width: 25%;
      margin-right: 20px;
      color:  #757575 ;
      text-align: right; }
    @media (max-width: 719px) {
      /* line 127, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer .table td {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
        border-top: none; }
        /* line 148, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
        .customer .table td.first {
          border-top: 1px solid  #ffffff ; }
        /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
        .customer .table td:before {
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: top;
          *vertical-align: auto;
          zoom: 1;
          *display: inline; } }

/* line 160, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .content-wrap {
  margin: 50px 0 85px;
  min-height: 200px; }
/* line 165, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .title {
  display: block;
  font-size: 14px;
  margin-bottom: 20px; }
  @media (max-width: 719px) {
    /* line 165, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer.account .title {
      text-align: center; } }
/* line 174, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .account-info, .customer.account .order-history {
  float: left; }
  @media (max-width: 719px) {
    /* line 174, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer.account .account-info, .customer.account .order-history {
      float: none; } }
/* line 179, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .account-info {
  font-size: 14px;
  width: 30%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    /* line 179, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer.account .account-info {
      width: 100%;
      padding-right: 0px;
      margin-bottom: 40px;
      text-align: center; } }
  /* line 191, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .account-info .name, .customer.account .account-info .email, .customer.account .account-info .address, .customer.account .account-info .city, .customer.account .account-info .country, .customer.account .account-info .view-addresses {
    display: block; }
  /* line 193, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .account-info .name {
    font-weight: 700;
    color:  #666666 ;
    font-family:  'Source Sans Pro', sans-serif ;
    text-transform:   ;
    margin-bottom: 12px;
    font-size:   ; }
  /* line 202, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .account-info .email {
    margin-bottom: 25px; }
    /* line 204, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer.account .account-info .email a {
      color: #b3b3b3;
      font-size: 12px; }
  /* line 214, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .account-info .address-wrap {
    margin-bottom: 25px; }
  /* line 218, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .account-info .view-addresses {
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    color:  #757575 ; }
/* line 226, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .order-history {
  width: 70%; }
  @media (max-width: 719px) {
    /* line 226, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
    .customer.account .order-history {
      width: 100%; } }
/* line 233, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .table.orders .order-number {
  width: 31.25%; }
  /* line 236, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .order-number a {
    color:  #b3b3b3 ;
    font-size: 16px; }
  /* line 241, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .order-number .date {
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    color:  #757575 ;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
    @media (max-width: 719px) {
      /* line 241, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
      .customer.account .table.orders .order-number .date {
        display: inline; } }
  /* line 250, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .order-number:before {
    content: "Order"; }
/* line 253, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .table.orders .payment-status {
  width: 25%; }
  /* line 255, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .payment-status:before {
    content: "Payment"; }
/* line 258, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .table.orders .fulfillment-status {
  width: 25%; }
  /* line 260, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .fulfillment-status:before {
    content: "Filled?"; }
/* line 263, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
.customer.account .table.orders .total {
  width: 18.75%;
  color:  #666666 ; }
  /* line 266, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .total:before {
    content: "Total"; }
@media (max-width: 719px) {
  /* line 270, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .order-number, .customer.account .table.orders .payment-status, .customer.account .table.orders .fulfillment-status, .customer.account .table.orders .total {
    width: 100%;
    padding: 12px 12px 12px 30px; }
  /* line 274, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .first {
    padding-top: 30px; }
  /* line 275, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_account.scss */
  .customer.account .table.orders .last {
    padding-bottom: 30px; } }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
.customer.addresses {
  padding-bottom: 80px; }
  @media (max-width: 719px) {
    /* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses {
      padding-bottom: 50px; } }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .address-list {
    width: 75%;
    padding: 15px 0 0px; }
    @media (max-width: 719px) {
      /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .address-list {
        width: 100%;
        padding-top: 0;
        text-align: center; } }
    /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .address-list .list li {
      padding: 35px 0;
      border-bottom: 1px solid #eeefea; }
      /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .address-list .list li.last {
        padding-bottom: 40px; }
    /* line 22, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .address-list .name {
      font-size: 14px;
      font-size:   ;
      font-weight: 700;
      color:  #666666 ;
      font-family:  'Source Sans Pro', sans-serif ;
      text-transform:   ;
      margin: 0 0 25px; }
      /* line 31, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .address-list .name .default {
        font-size: 12px;
        font-weight: 400;
        color:  #757575 ;
        margin-left: 2px; }
    /* line 39, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .address-list .address-1, .customer.addresses .address-list .address-2, .customer.addresses .address-list .address-3 {
      font-size: 14px;
      margin: 3px 0; }
    /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .address-list .action {
      display: block;
      margin-top: 25px; }
      /* line 48, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .address-list .action a {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px;
        color:  #757575 ; }
        /* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
        .customer.addresses .address-list .action a:first-child {
          margin-right: 15px; }
  /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .add-new {
    display: block;
    font-size: 14px;
    color: #b3b3b3;
    margin-top: 45px; }
    @media (max-width: 719px) {
      /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .add-new {
        text-align: center; } }
  /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .add-address {
    margin-top: 45px;
    padding-top: 40px;
    width: 75%;
    border-top: 1px solid #ffffff; }
    @media (max-width: 719px) {
      /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .add-address {
        width: 100%; } }
    /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .add-address.new-user {
      display: block !important;
      padding-top: 0;
      border-top: 0; }
  @media (max-width: 719px) {
    /* line 80, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address {
      text-align: left; } }
  /* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .edit-add-address .wrap {
    width: 75%;
    margin-bottom: 22px; }
    @media (max-width: 719px) {
      /* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .edit-add-address .wrap {
        width: 100%; } }
    /* line 93, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .wrap > label {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline;
      font-size: 14px;
      margin-bottom: 12px; }
    /* line 98, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .wrap .field {
      display: block;
      width: 100%; }
    /* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .wrap .styled-select, .customer.addresses .edit-add-address .wrap .pxuSexyDropWrapper {
      display: block;
      width: 330px;
      height: 32px; }
      @media (max-width: 719px) {
        /* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
        .customer.addresses .edit-add-address .wrap .styled-select, .customer.addresses .edit-add-address .wrap .pxuSexyDropWrapper {
          width: 100%; } }
    /* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .wrap .styled-select {
      top: 5px; }
  /* line 115, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .edit-add-address .default-wrap {
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    color:  #757575 ;
    margin: 35px 0 45px; }
    /* line 121, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .default-wrap input, .customer.addresses .edit-add-address .default-wrap span {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
    /* line 125, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .default-wrap input {
      margin-right: 4px; }
  /* line 130, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
  .customer.addresses .edit-add-address .submit-wrap span {
    font-family:  'Source Sans Pro', sans-serif ;
    color:  #757575 ;
    font-size: 12px;
    margin-left: 15px; }
    /* line 136, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .submit-wrap span a {
      color: #ababab; }
  @media (max-width: 719px) {
    /* line 129, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
    .customer.addresses .edit-add-address .submit-wrap {
      text-align: center; }
      /* line 141, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .edit-add-address .submit-wrap .action-button {
        width: 100%; }
      /* line 144, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_addresses.scss */
      .customer.addresses .edit-add-address .submit-wrap span {
        display: block;
        margin: 25px 0 15px; } }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_login.scss */
.customer.login #recover-password {
  display: none; }
/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_login.scss */
.customer.login .secondary-wrap {
  width: 100%;
  padding-top: 35px;
  margin-top: 35px;
  border-top: 1px solid  #ffffff ; }
  @media (max-width: 719px) {
    /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_login.scss */
    .customer.login .secondary-wrap {
      text-align: center; }
      /* line 16, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_login.scss */
      .customer.login .secondary-wrap .action-button {
        width: 100%; } }
  /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_login.scss */
  .customer.login .secondary-wrap p {
    font-size: 14px;
    margin: 0 0 20px; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
.customer.order .content-wrap {
  margin: 50px 0 85px; }
  @media (max-width: 719px) {
    /* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .content-wrap {
      margin: 30px 0 55px; } }
/* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
.customer.order .shipping-info, .customer.order .order-history {
  float: left; }
  @media (max-width: 719px) {
    /* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .shipping-info, .customer.order .order-history {
      float: none; } }
/* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
.customer.order .shipping-info {
  font-size: 14px;
  line-height: 22px;
  width: 30%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    /* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .shipping-info {
      width: 100%;
      padding-right: 0px;
      margin-bottom: 40px;
      text-align: center; } }
  /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .shipping-info .shipping-title {
    display: block;
    font-weight: 700;
    color:  #666666 ;
    font-family:  'Source Sans Pro', sans-serif ;
    text-transform:   ;
    margin-bottom: 10px;
    font-size:   ; }
  /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .shipping-info .address, .customer.order .shipping-info .city, .customer.order .shipping-info .country {
    display: block; }
  /* line 38, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .shipping-info .address-wrap {
    margin-bottom: 30px; }
/* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
.customer.order .order-history {
  width: 70%; }
  @media (max-width: 719px) {
    /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history {
      width: 100%; } }
  @media (max-width: 719px) {
    /* line 50, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td {
      width: 100% !important;
      padding: 12px 12px 12px 30px !important; } }
  @media (max-width: 719px) {
    /* line 56, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.first {
      padding-top: 30px !important; } }
  @media (max-width: 719px) {
    /* line 57, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.last {
      padding-bottom: 30px !important; } }
  /* line 59, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .order-history .table td.product-item {
    width: 46%; }
    /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.product-item .wrap, .customer.order .order-history .table td.product-item .image-wrap {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
    /* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.product-item .wrap {
      width: 75%;
      padding-left: 30px; }
      @media (max-width: 719px) {
        /* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .table td.product-item .wrap {
          padding-left: 20px; } }
    /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.product-item .image-wrap {
      width: 25%;
      position: relative; }
      /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.product-item .image-wrap img {
        width: 100%; }
      /* line 75, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.product-item .image-wrap a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
        /* line 82, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .table td.product-item .image-wrap a.overlay {
          -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
    /* line 86, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.product-item .label {
      display: block; }
      /* line 89, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.product-item .label.vendor {
        color:  #757575 ;
        font-size: 10px;
        margin-bottom: 10px; }
      /* line 95, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.product-item .label.title {
        margin-bottom: 15px; }
        /* line 97, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .table td.product-item .label.title a {
          color: #b3b3b3;
          font-size: 16px; }
      /* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.product-item .label.variant {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px; }
  /* line 109, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .order-history .table td.price {
    width: 18%; }
    /* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.price:before {
      content: "Price"; }
  /* line 113, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .order-history .table td.quantity {
    width: 18%;
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    color:  #757575 ; }
    /* line 118, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.quantity:before {
      content: 'Quantity';
      font-family:  'Source Sans Pro', sans-serif ;
      font-size: 14px;
      color:  #757575 ;
      top: 9px; }
    @media (max-width: 719px) {
      /* line 125, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .table td.quantity span {
        position: relative;
        top: 3px; } }
  /* line 132, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .order-history .table td.total {
    width: 18%; }
    /* line 134, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .table td.total:before {
      content: "Total"; }
  /* line 139, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
  .customer.order .order-history .order-totals {
    width: 100%; }
    @media (max-width: 719px) {
      /* line 139, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals {
        display: none; } }
    /* line 143, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
    .customer.order .order-history .order-totals ul {
      border-bottom: 1px solid #eeefea;
      padding: 30px 0;
      text-align: right;
      font-size: 14px; }
      /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals ul.sub-total {
        padding: 30px 0 35px; }
      /* line 151, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals ul li {
        text-align: right;
        padding: 10px 0; }
        /* line 155, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .order-totals ul li.order-subtotal {
          padding-top: 0; }
        /* line 156, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .order-totals ul li.order-tax.last {
          padding-bottom: 0; }
        /* line 158, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
        .customer.order .order-history .order-totals ul li.order-total {
          padding: 3px 0 5px; }
          /* line 160, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
          .customer.order .order-history .order-totals ul li.order-total span {
            color: #666666; }
      /* line 164, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals ul h2, .customer.order .order-history .order-totals ul span {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
      /* line 166, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals ul h2 {
        font-weight: 400;
        font-size: 14px; }
      /* line 171, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/customer/_order.scss */
      .customer.order .order-history .order-totals ul span {
        width: 90px;
        text-align: left;
        margin-left: 30px; }

/* Snippets */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_breadcrumbs.scss */
.breadcrumb-navigation {
  font-family:  'Source Sans Pro', sans-serif ;
  font-size: 12px;
  padding: 45px 90px 5px 0px; }
  @media (max-width: 719px) {
    /* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_breadcrumbs.scss */
    .breadcrumb-navigation {
      display: none; } }
  /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_breadcrumbs.scss */
  .breadcrumb-navigation a {
    color:  #b3b3b3 ; }
  /* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_breadcrumbs.scss */
  .breadcrumb-navigation a:hover {
    color:  #222222 ; }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
.pagination {
  padding-bottom: 10px;
  text-align: center; }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
  .pagination > li {
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    border-left: 1px solid  #eeefea ;
    padding: 3px 0 3px 8px;
    margin-left: 8px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
    /* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
    .pagination > li:first-child {
      border-left: none;
      padding-left: 0;
      margin-left: 8px; }
    /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
    .pagination > li.previous a, .pagination > li.next a {
      color:  #b3b3b3 ; }
      /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
      .pagination > li.previous a:hover, .pagination > li.next a:hover {
        color:  #222222 ; }
    /* line 24, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_pagination.scss */
    .pagination > li.position {
      color: ; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
.template-list-collections .collections {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media (max-width: 719px) {
    /* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .template-list-collections .collections {
      padding-top: 10px; } }
/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
.template-list-collections .collection-wrap {
  margin-bottom: 25px; }
/* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
.template-list-collections .rte {
  margin-top: 20px; }
  @media (max-width: 719px) {
    /* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .template-list-collections .rte {
      display: none; } }

/* line 22, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
.collections, .featured-collections {
  margin-left: -30px; }
  @media (max-width: 719px) {
    /* line 22, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections, .featured-collections {
      margin-left: 0; } }
  /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
  .collections .collection-wrap, .featured-collections .collection-wrap {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    width: 33.3333%;
    padding-left: 30px; }
    @media (max-width: 719px) {
      /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
      .collections .collection-wrap, .featured-collections .collection-wrap {
        width: 60%;
        min-width: 290px;
        display: block;
        margin: 0 auto 35px;
        padding: 0; } }
  /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
  .collections .collection, .featured-collections .collection {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    position: relative;
    width: 100%;
    padding-bottom: 76.66666667%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat; }
    /* line 50, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection:hover .label, .featured-collections .collection:hover .label {
      visibility: hidden; }
    /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection:hover .browse, .featured-collections .collection:hover .browse {
      visibility: visible; }
    /* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection:hover a, .featured-collections .collection:hover a {
      background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/ie-product-overlay-bg.png?v=83967569880151450761462307375 ) repeat 50% 50%;
      background: rgba(0, 0, 0, 0.5); }
    @media (max-width: 719px) {
      /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
      .collections .collection, .featured-collections .collection {
        height: 230px; }
        /* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
        .collections .collection:hover .browse, .featured-collections .collection:hover .browse {
          visibility: hidden; }
        /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
        .collections .collection:hover .label, .featured-collections .collection:hover .label {
          visibility: visible; }
        /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
        .collections .collection:hover a, .featured-collections .collection:hover a {
          background: transparent !important; } }
    /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection .label, .collections .collection .browse, .featured-collections .collection .label, .featured-collections .collection .browse {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1000;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33);
      margin: 0;
      padding: 0 30px; }
    /* line 82, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection .label, .featured-collections .collection .label {
      font-size: 21px;
      font-size:   ;
      font-family:  'Source Sans Pro', sans-serif ;
      text-transform:   ; }
      /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
      .collections .collection .label.preload, .featured-collections .collection .label.preload {
        visibility: hidden; }
    /* line 90, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection .browse, .featured-collections .collection .browse {
      visibility: hidden;
      font-size: 14px;
      line-height: 30px; }
    /* line 97, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_collections.scss */
    .collections .collection a, .featured-collections .collection a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000;
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
.sidebar {
  float: right;
  width: 25%;
  padding-left: 3%;
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
  @media (max-width: 719px) {
    /* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar {
      width: 100%;
      margin-top: 45px;
      margin-bottom: 10px;
      padding-left: 0px; } }
  /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .module {
    border-top: 1px solid  #eeefea ;
    padding: 25px 0; }
    /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .module.first {
      padding-top: 0;
      border-top: none; }
    /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .module > label {
      display: block;
      margin-bottom: 22px;
      font-size: 14px;
      color: #666666;
      cursor: auto; }
  /* line 34, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .recent-posts .post {
    margin: 14px 0; }
    /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .recent-posts .post.first {
      margin-top: 0px; }
    /* line 37, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .recent-posts .post.last {
      margin-bottom: 0px; }
  /* line 39, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .recent-posts .title {
    font-size: 14px;
    color: #666666;
    margin-bottom: 6px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
    /* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .recent-posts .title:hover {
      color: #b3b3b3; }
  /* line 47, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .recent-posts .date {
    display: block;
    font-size: 12px;
    display:none;
    color:  #757575 ; }
  /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .tags-list .tag {
    margin: 12px 0; }
    /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .tags-list .tag a {
      font-size: 14px;
      color: #666666; }
      /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
      .sidebar .tags-list .tag a:hover {
        color: #b3b3b3; }
    /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .tags-list .tag .count {
      font-size: 12px;
      color:  #afacac ;
      margin-left: 5px; }
    /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .tags-list .tag:hover .count {
      color:  #777777 ; }
  /* line 81, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .twitter .tweets {
    margin-bottom: 20px; }
  /* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .twitter .tweet {
    margin-top: 22px; }
    /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .twitter .tweet:first-child {
      margin-top: 0px; }
  /* line 90, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .twitter .text {
    font-style: italic;
    font-size: 14px;
    line-height: 22px;
    margin: 0; }
    /* line 96, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
    .sidebar .twitter .text a {
      color: #b3b3b3; }
  /* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .twitter .date {
    font-size: 12px;
    color:  #757575 ;
    margin-top: 14px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
  /* line 107, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_blog-sidebar.scss */
  .sidebar .twitter .follow-us {
    font-size: 14px; }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
.product-list {
  text-align: left;
  margin-left: -30px; }
  @media (max-width: 719px) {
    /* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product-list {
      margin-left: 0; } }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
.product {
  width: 25%;
  padding-left: 30px;
  text-align: center;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  /* -------------------------- */
  /* -------------------------- */
  /* -------------------------- */ }
  @media (max-width: 719px) {
    /* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product {
      display: block;
      width: 60% !important;
      min-width: 290px !important;
      margin: 0 auto 35px;
      padding-left: 0px;
      text-align: center; }
      /* line 23, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
      .product.last {
        margin-bottom: 0px; } }
  /* line 28, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product figure {
    position: relative;margin-bottom:15px }
    /* line 31, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product figure > img {
      width: 100%;
      vertical-align: top; }
    /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product figure > a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000;
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
      /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
      .lt-ie9 .product figure > a {
        border: 1px solid  #ffffff ; }
  /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3001;
    background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/ie-product-overlay-bg.png?v=83967569880151450761462307375 ) repeat 50% 50%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
    /* line 63, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product .overlay:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
    /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product .overlay .label {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 14px;
      line-height: 30px;
      padding: 0 15px;
      color: #ffffff; }
    @media (max-width: 719px) {
      /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
      .product .overlay {
        display: none !important; } }
  /* line 84, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .quick-shop-content {
    display: none; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 93, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
.product .brand, .quick-shop-content .brand, #product-area .brand, .mobile-product-title .brand {
  font-size: 12px;display:none;
  margin: 25px 0 0; }
  @media (max-width: 719px) {
    /* line 93, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product .brand, .quick-shop-content .brand, #product-area .brand, .mobile-product-title .brand {
      margin-top: 30px; } }
  /* line 100, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .brand a, .quick-shop-content .brand a, #product-area .brand a, .mobile-product-title .brand a {
    color:  #757575 ; }
/* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
.product .title, .quick-shop-content .title, #product-area .title, .mobile-product-title .title {
  font-size: 16px;display:block;
  font-size:   ;
  font-weight: 700;
  text-align:center;
  color: #666666;
  font-family:  'Source Sans Pro', sans-serif ;
  text-transform:   ;
  margin: 10px 0 0; }
  /* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .title a, .quick-shop-content .title a, #product-area .title a, .mobile-product-title .title a {
    color: #666666; }
    /* line 113, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
    .product .title a:hover, .quick-shop-content .title a:hover, #product-area .title a:hover, .mobile-product-title .title a:hover {
      color: #b3b3b3; }
/* line 116, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
.product .price, .quick-shop-content .price, #product-area .price, .mobile-product-title .price {
  font-size: 18px;
  color: ;
  margin: 0 0 15px 0; }
  /* line 121, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .price .label, .quick-shop-content .price .label, #product-area .price .label, .mobile-product-title .price .label {
    color: #666666;
    margin-right: 5px; }
  /* line 125, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-item.scss */
  .product .price .original, .quick-shop-content .price .original, #product-area .price .original, .mobile-product-title .price .original {
    text-decoration: line-through;
    color: #AAA;
    margin-right: 10px; }

/*
The quick shop product area derives almost all 
of it's styles from the product page. Styles can
be found in product.scss
*/
/* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
.quick-shop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/ie-product-overlay-bg.png?v=83967569880151450761462307375 ) repeat 50% 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  overflow: auto;
  -moz-opacity: 0;
  opacity: 0; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 25, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
.quick-shop-modal {
  width: 72%;
  min-width: 690px;
  max-width: 1028px;
  padding: 15px;
  margin: 0 auto; }

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
/* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
.quick-shop-content {
  background:  #ffffff ;
  padding: 42px 30px 65px;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2); }
  /* line 41, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
  .quick-shop-content .details .header {
    padding-bottom: 26px !important; }
    /* line 43, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
    .quick-shop-content .details .header .title {
      font-size: 28px !important;
      margin: 8px 0 15px !important; }
  /* line 49, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
  .quick-shop-content .details .go-to-product span {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: relative;
    top: -1px; }
  /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_product-quick-shop.scss */
  .quick-shop-content .details .error-wrap {
    font-size: 14px;
    font-style: italic;
    color:  #b3b3b3 ; }

/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
#shipping-calculator {
  padding-top: 40px;
  border-top: 1px solid #ffffff; }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
  #shipping-calculator .wrapper-setup {
    width: 40%;
    float: left; }
    @media (max-width: 719px) {
      /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
      #shipping-calculator .wrapper-setup {
        width: 100%; } }
    /* line 11, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup p {
      margin: 25px 0; }
      /* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
      #shipping-calculator .wrapper-setup p:first-child {
        margin-top: 0; }
    /* line 16, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup label, #shipping-calculator .wrapper-setup select {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
    /* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup label {
      width: 50%;
      font-size: 14px;
      text-align: right;
      padding-right: 30px;
      cursor: auto; }
      @media (max-width: 719px) {
        /* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
        #shipping-calculator .wrapper-setup label {
          width: 100%;
          text-align: left;
          display: block;
          padding-right: 0px;
          margin-bottom: 15px; } }
    /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup #address_country, #shipping-calculator .wrapper-setup #address_province, #shipping-calculator .wrapper-setup #address_zip {
      width: 50%; }
      @media (max-width: 719px) {
        /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
        #shipping-calculator .wrapper-setup #address_country, #shipping-calculator .wrapper-setup #address_province, #shipping-calculator .wrapper-setup #address_zip {
          width: 100%; } }
    /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup .pxuSexyDropWrapper {
      width: 50% !important; }
      @media (max-width: 719px) {
        /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
        #shipping-calculator .wrapper-setup .pxuSexyDropWrapper {
          width: 100% !important; } }
      /* line 48, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
      #shipping-calculator .wrapper-setup .pxuSexyDropWrapper #address_country, #shipping-calculator .wrapper-setup .pxuSexyDropWrapper #address_province {
        width: 100%; }
    /* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator .wrapper-setup .input-wrap {
      width: 50%;
      float: right; }
      @media (max-width: 719px) {
        /* line 53, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
        #shipping-calculator .wrapper-setup .input-wrap {
          width: 100%;
          margin-top: 10px; }
          /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
          #shipping-calculator .wrapper-setup .input-wrap .action-button {
            width: 100%; } }
  /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
  #shipping-calculator #wrapper-response {
    width: 50%;
    float: right;
    font-size: 14px; }
    @media (max-width: 719px) {
      /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
      #shipping-calculator #wrapper-response {
        width: 100%;
        margin-top: 10px; } }
    /* line 75, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator #wrapper-response .message {
      font-style: italic;
      margin: 0 0 25px; }
    /* line 80, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/snippets/_shipping-calculator.scss */
    #shipping-calculator #wrapper-response #shipping-rates {
      list-style: disc;
      padding-left: 20px;
      color:  #757575 ; }

/* Templates */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
.template-index {
  /* Slideshow */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* Welcome text */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* Featured collections */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* Featured products */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* From the blog */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
  /* line 6, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slideshow {
    position: relative; }
  /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slideshow:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2500; }
  /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide {
    width: 100%;
    float: left;
    margin-right: -100%;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    text-align: center;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
    /* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide.active {
      z-index: 2000; }
  /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .image-wrap {
    position: relative; }
  /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide img {
    width: 100%; }
  /* line 42, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .jump-to-slide {
    position: absolute;
    bottom: 2px;
    left: 10px;
    z-index: 6000;
    display: block;
    height: 32px;
    max-width: 150px;
    overflow: hidden; }
    /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .jump-to-slide li {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline;
      height: 11px;
      width: 11px;
      margin: 0 8px;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      border-radius: 11px; }
      /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .jump-to-slide li.active, .template-index .slide .jump-to-slide li:hover {
        background-color: rgba(255, 255, 255, 0.7); }
  /* line 67, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide.invert .jump-to-slide li {
    background-color: rgba(0, 0, 0, 0.5); }
    /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide.invert .jump-to-slide li.active, .template-index .slide.invert .jump-to-slide li:hover {
      background-color: rgba(0, 0, 0, 0.7); }
  /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .prev, .template-index .slide .next {
    display: block;
    position: absolute;
    top: 50%;
    z-index: 3000;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    cursor: pointer; }
    /* line 83, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .prev .icon, .template-index .slide .next .icon {
      font-family: 'icons';
      font-size: 99px;
      color: #ffffff;
      line-height: 62px;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5; }
      /* line 90, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .prev .icon:hover, .template-index .slide .next .icon:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
        opacity: 0.75; }
    @media (max-width: 938px) {
      /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .prev, .template-index .slide .next {
        width: 39px;
        height: 39px;
        top: auto;
        bottom: 1px; }
        /* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .prev .icon, .template-index .slide .next .icon {
          font-size: 65px;
          line-height: 40px; } }
  /* line 106, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide.invert .icon, .template-index .slide.invert .icon {
    color: #000000; }
  /* line 110, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .prev {
    left: 0; }
    @media (max-width: 938px) {
      /* line 110, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .prev {
        left: auto;
        right: 40px; } }
  /* line 115, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .next {
    right: 0; }
  /* line 120, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .content-outer-wrap {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 84%;
    max-width: 1030px; }
    @media (max-width: 719px) {
      /* line 120, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .content-outer-wrap {
        position: relative;
        left: 0;
        background-color:  #b3b3b3 ;
        width: 100%; } }
  /* line 136, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .mobile-link {
    display: none; }
    @media (max-width: 719px) {
      /* line 136, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .mobile-link {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000; } }
  /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .content-inner-wrap {
    position: absolute;
    left: -50%;
    top: 0;
    bottom: 0;
    width: 100%; }
    @media (max-width: 719px) {
      /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .content-inner-wrap {
        position: static; } }
  /* line 159, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .slide .content {
    position: absolute;
    text-align: left;
    padding: 0 15px; }
    @media (max-width: 719px) {
      /* line 159, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .content {
        position: static;
        text-align: center;
        padding: 20px 15px; } }
    /* line 170, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content.left {
      left: 0; }
    /* line 171, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content.bottom {
      bottom: 10%; }
    /* line 172, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content.top {
      top: 10%; }
    /* line 173, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content.center {
      left: 0;
      right: 0;
      text-align: center; }
    /* line 178, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content.right {
      right: 0;
      text-align: right; }
      @media (max-width: 719px) {
        /* line 178, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content.right {
          text-align: center; } }
    /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content .title {
      font-size: 56px;
      font-size:   ;
      font-weight: 700;
      color: #ffffff;
      cursor: default;
      font-family:  'Source Sans Pro', sans-serif ;
      text-transform:   ;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33); }
      @media (max-width: 719px) {
        /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .title {
          font-size: 36px;
          font-size:   ;
          white-space: nowrap;
          overflow-x: hidden;
          text-shadow: none; } }
      @media (max-width: 480px) {
        /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .title {
          font-size: 21px; } }
    /* line 203, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content .tagline {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 4px;
      cursor: default;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33); }
      @media (max-width: 719px) {
        /* line 203, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .tagline {
          font-size: 16px;
          margin-top: 10px;
          white-space: nowrap;
          overflow-x: hidden;
          text-shadow: none; } }
      @media (max-width: 480px) {
        /* line 203, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .tagline {
          font-size: 14px; } }
    /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .slide .content .call-to-action {
      padding: 20px;
      line-height: 1.0em;
      position: relative;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.1em;
      color:  #ffffff ;
      background-color:  #b3b3b3 ;
      margin-top: 24px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
      /* line 233, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .content .call-to-action:hover {
        background-color:  #222222 ; }
      @media (max-width: 719px) {
        /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .call-to-action {
          font-size: 12px;
          margin-top: 0;
          white-space: nowrap;
          padding-bottom: 0px; } }
      @media (max-width: 480px) {
        /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .slide .content .call-to-action {
          font-size: 10px; } }
      /* line 246, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .slide .content .call-to-action .arrow {
        font-family: 'icons';
        font-size: 22px; }
        @media (max-width: 719px) {
          /* line 246, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
          .template-index .slide .content .call-to-action .arrow {
            font-size: 18px; } }
        @media (max-width: 480px) {
          /* line 246, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
          .template-index .slide .content .call-to-action .arrow {
            font-size: 15px; } }
  /* line 264, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .welcome-area {
    text-align: center;
    padding: 55px 0; }
    @media (max-width: 719px) {
      /* line 264, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .welcome-area {
        padding: 30px 0 35px; } }
    /* line 272, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .welcome-area h1, .template-index .welcome-area h2 {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline;
      width: 70%;
      margin: 10px 0; }
    /* line 278, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .welcome-area h1 {
      font-size: 28px;
      font-size:   ;
      font-weight: 700;
      color: #666666;
      font-family:  'Source Sans Pro', sans-serif ;
      text-transform:   ;
      line-height: 33px; }
      @media (max-width: 719px) {
        /* line 278, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .welcome-area h1 {
          font-size: 21px;
          font-size:   ; } }
    /* line 293, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .welcome-area h2 {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline;
      font-size: 18px;
      font-weight: 400;
      color:  #ababab ;
      line-height: 29px; }
      @media (max-width: 719px) {
        /* line 293, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .welcome-area h2 {
          font-size: 16px;
          line-height: 25px; } }
  /* line 312, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .featured-collections {
    text-align: center;
    margin-left: 0px; }
    /* line 317, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .featured-collections.first .section-title {
      border-top: 0px;
      padding-top: 75px; }
    /* line 323, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .featured-collections .wrap {
      margin-left: -30px; }
      @media (max-width: 719px) {
        /* line 323, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .featured-collections .wrap {
          margin-left: 0; } }
    /* line 328, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .featured-collections .collection-wrap {
      margin-bottom: 65px; }
  /* line 338, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .featured-products {
    text-align: center;
    padding-bottom: 65px; }
    /* line 343, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .featured-products.first .section-title {
      border-top: 0px;
      padding-top: 75px; }
    /* line 349, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .featured-products.multi-row {
      padding-bottom: 23px; }
      /* line 351, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .featured-products.multi-row .product {
        margin-bottom: 42px; }
  /* line 360, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .blog {
    padding-bottom: 65px; }
    /* line 364, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .blog.first .section-title {
      border-top: 0px;
      padding-top: 75px; }
  /* line 371, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .articles {
    margin-left: -30px; }
    @media (max-width: 719px) {
      /* line 371, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .articles {
        margin-left: 0; } }
  /* line 376, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .template-index .article {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    width: 25%;
    text-align: left;
    padding-left: 30px; }
    /* line 382, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .article.first {
      margin-left: 0; }
    @media (max-width: 719px) {
      /* line 376, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .article {
        display: block;
        width: 60%;
        min-width: 290px;
        margin: 0 auto 30px;
        margin-bottom: 30px;
        padding-left: 0px; }
        /* line 392, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .article.first {
          margin: 0 auto 30px; }
        /* line 393, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
        .template-index .article.last {
          margin-bottom: 0px; } }
    /* line 396, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .article .date {
      display: block;
      font-size: 12px;
      color:  #757575 ;
      margin-bottom: 10px; }
    /* line 404, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .article .title {
      display: block;
      font-weight: 700;
      font-size: 16px;
      font-size:   ;
      font-family:  'Source Sans Pro', sans-serif ;
      text-transform:   ;
      margin-bottom: 18px;
      color:  #666666 ; }
      /* line 413, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .article .title:hover {
        color: #b3b3b3; }
    /* line 416, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
    .template-index .article .rte {
      color: ;
      font-size: 14px;
      line-height: 1.6; }
      /* line 421, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
      .template-index .article .rte img {
        width: 100%; }

/* line 434, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
.disable-headers.template-index .section-title {
  display: none; }
/* line 436, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
.disable-headers.template-index .featured-products, .disable-headers.template-index .blog {
  padding-top: 50px;
  border-top: 1px solid #ffffff; }
  /* line 440, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
  .disable-headers.template-index .featured-products.first, .disable-headers.template-index .blog.first {
    padding-top: 60px;
    border-top: 0px; }
/* line 447, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_home.scss */
.disable-headers.template-index .featured-collections.first {
  margin-top: 65px; }

/* Both blog and article template */
/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
.template-blog, .template-article {
  /* Articles */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* Pagination */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
  /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-blog .content-wrap, .template-article .content-wrap {
    padding-top: 40px;
    padding-bottom: 80px; }
    @media (max-width: 719px) {
      /* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .content-wrap, .template-article .content-wrap {
        padding-top: 0;
        padding-bottom: 0; } }
  /* line 17, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-blog .articles, .template-article .articles {
    float: left;
    width: 75%;
    padding-right: 3%;
    /* Article meta */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* Article content */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
    @media (max-width: 719px) {
      /* line 17, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles, .template-article .articles {
        width: 100%;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 15px;
        padding-right: 0px; } }
    /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-blog .articles .meta, .template-article .articles .meta {
      float: left;
      width: 30%;
      padding-right: 6%;
      position: relative;
      display:none;
      /* MOBILE: Article meta */
      /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
      @media (max-width: 719px) {
        /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta, .template-article .articles .meta {
          width: 100%;
          border-bottom: 1px solid  #eeefea ;
          margin-bottom: 35px;
          padding-right: 0px; } }
      /* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .meta li, .template-article .articles .meta li {
        margin-bottom: 28px;
        padding-top: 28px;
        border-top: 1px solid  #eeefea ;
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
        @media (max-width: 719px) {
          /* line 45, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li, .template-article .articles .meta li {
            border-top: none; } }
        /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li label, .template-blog .articles .meta li .text, .template-blog .articles .meta li .text a, .template-article .articles .meta li label, .template-article .articles .meta li .text, .template-article .articles .meta li .text a {
          color:  #afacac ; }
        /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li label, .template-article .articles .meta li label {
          display: block;
          font-size: 12px;
          cursor: auto; }
          /* line 64, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li label a, .template-article .articles .meta li label a {
            color:  #afacac ; }
            /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li label a:hover, .template-article .articles .meta li label a:hover {
              color:  #777777 ; }
        /* line 70, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li .text, .template-article .articles .meta li .text {
          font-family:  'Source Sans Pro', sans-serif ;
          font-size: 12px; }
          /* line 74, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li .text a:hover, .template-article .articles .meta li .text a:hover {
            color:  #777777 ; }
        /* line 81, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.title, .template-article .articles .meta li.title {
          display: none; }
          @media (max-width: 719px) {
            /* line 81, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.title, .template-article .articles .meta li.title {
              display: block;
              text-align: center;
              border-top: 1px solid  #eeefea ;
              padding: 40px 0 0;
              margin-bottom: 25px; } }
        /* line 95, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.date, .template-article .articles .meta li.date {
          border-top: 0;
          padding-top: 0; }
          @media (max-width: 719px) {
            /* line 95, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.date, .template-article .articles .meta li.date {
              width: 100%;
              text-align: center;
              padding-bottom: 35px;
              margin-bottom: 0px; } }
          /* line 106, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.date label, .template-article .articles .meta li.date label {
            font-size: 14px; }
        @media (max-width: 719px) {
          /* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.author, .template-blog .articles .meta li.tags, .template-blog .articles .meta li.comments, .template-article .articles .meta li.author, .template-article .articles .meta li.tags, .template-article .articles .meta li.comments {
            display: none; } }
        /* line 116, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.author .avatar-wrap, .template-article .articles .meta li.author .avatar-wrap {
          width: 54px;
          height: 54px;
          overflow: hidden;
          margin-bottom: 15px;
          -webkit-border-radius: 54px;
          -moz-border-radius: 54px;
          -ms-border-radius: 54px;
          -o-border-radius: 54px;
          border-radius: 54px; }
        /* line 124, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.author .avatar, .template-article .articles .meta li.author .avatar {
          width: 54px; }
          @media (max-width: 719px) {
            /* line 124, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.author .avatar, .template-article .articles .meta li.author .avatar {
              width: 46px; } }
        @media (max-width: 719px) {
          /* line 133, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.tags, .template-article .articles .meta li.tags {
            float: right;
            padding-right: 20px;
            text-align: center; } }
        /* line 144, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.prev-next-post .label, .template-article .articles .meta li.prev-next-post .label {
          display: inline; }
        /* line 145, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
          display: none; }
        @media (max-width: 719px) {
          /* line 143, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.prev-next-post, .template-article .articles .meta li.prev-next-post {
            height: 0;
            width: 0;
            padding: 0;
            margin: 0; }
            /* line 153, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .label, .template-article .articles .meta li.prev-next-post .label {
              display: none; }
            /* line 154, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
              display: block; }
            /* line 156, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .previous, .template-blog .articles .meta li.prev-next-post .next, .template-article .articles .meta li.prev-next-post .previous, .template-article .articles .meta li.prev-next-post .next {
              position: absolute;
              height: 22px;
              width: 22px;
              top: 50%;
              margin-top: -11px;
              font-family: 'icons'; }
            /* line 165, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .next, .template-article .articles .meta li.prev-next-post .next {
              left: 0; }
            /* line 166, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .previous, .template-article .articles .meta li.prev-next-post .previous {
              right: 0; }
            /* line 167, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .slash, .template-article .articles .meta li.prev-next-post .slash {
              display: none; }
            /* line 169, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
              font-family: 'icons';
              font-size: 50px;
              color: #eeefea;
              line-height: 16px;
              text-indent: 0px;
              text-transform: lowercase; }
            /* line 178, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .next .icon, .template-article .articles .meta li.prev-next-post .next .icon {
              text-indent: 2px; }
            /* line 179, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta li.prev-next-post .previous .icon, .template-article .articles .meta li.prev-next-post .previous .icon {
              text-indent: -11px; } }
        @media (max-width: 719px) {
          /* line 186, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.share-buttons, .template-article .articles .meta li.share-buttons {
            display: none; } }
        /* line 191, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta li.share-buttons .share-wrap, .template-article .articles .meta li.share-buttons .share-wrap {
          width: 100%;
          height: 42px;
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: top;
          *vertical-align: auto;
          zoom: 1;
          *display: inline; }
          /* line 196, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.share-buttons .share-wrap > iframe, .template-blog .articles .meta li.share-buttons .share-wrap > div, .template-article .articles .meta li.share-buttons .share-wrap > iframe, .template-article .articles .meta li.share-buttons .share-wrap > div {
            display: -moz-inline-stack;
            display: inline-block;
            vertical-align: top;
            *vertical-align: auto;
            zoom: 1;
            *display: inline; }
          /* line 200, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.share-buttons .share-wrap.first, .template-article .articles .meta li.share-buttons .share-wrap.first {
            border-top: 1px solid ; }
          /* line 205, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta li.share-buttons .share-wrap.facebook .fb_iframe_widget span, .template-article .articles .meta li.share-buttons .share-wrap.facebook .fb_iframe_widget span {
            vertical-align: top; }
      /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .meta.mobile, .template-article .articles .meta.mobile {
        display: none;
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
        /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
        @media (max-width: 719px) {
          /* line 220, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta.mobile, .template-article .articles .meta.mobile {
            display: block;
            border-top: 1px solid  #eeefea ;
            border-bottom: none;
            float: none;
            margin-bottom: 0;
            margin-top: 10px; } }
        /* line 234, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
          display: none; }
          @media (max-width: 719px) {
            /* line 234, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
              display: block;
              width: 50%;
              margin: 0;
              padding: 20px 0; }
              /* line 242, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
              .template-blog .articles .meta.mobile li.author label, .template-blog .articles .meta.mobile li.tags label, .template-article .articles .meta.mobile li.author label, .template-article .articles .meta.mobile li.tags label {
                margin-top: 5px; } }
          @media (max-width: 480px) {
            /* line 234, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
              width: 100%;
              text-align: center; } }
        @media (max-width: 719px) {
          /* line 254, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.author {
            float: left;
            padding-left: 20px; }
            /* line 259, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta.mobile li.author .avatar-wrap, .template-article .articles .meta.mobile li.author .avatar-wrap {
              float: left;
              width: 46px;
              height: 46px;
              margin: 0 10px 0 0; }
            /* line 266, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta.mobile li.author .avatar, .template-article .articles .meta.mobile li.author .avatar {
              width: 46px; } }
        @media (max-width: 480px) {
          /* line 254, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.author {
            float: none; }
            /* line 274, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .meta.mobile li.author .avatar-wrap, .template-article .articles .meta.mobile li.author .avatar-wrap {
              float: none;
              margin: 0 auto 10px; } }
        @media (max-width: 480px) {
          /* line 285, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.tags {
            padding-top: 0px; } }
        /* line 293, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .meta.mobile li.share-buttons, .template-article .articles .meta.mobile li.share-buttons {
          display: block;
          width: 100%;
          padding-top: 30px;
          margin-bottom: 30px;
          float: left;
          text-align: center;
          border-top: 1px solid #ffffff; }
          /* line 302, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .meta.mobile li.share-buttons .share-wrap, .template-article .articles .meta.mobile li.share-buttons .share-wrap {
            width: auto;
            height: 20px;
            margin: 0 8px; }
    /* line 317, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-blog .articles .article, .template-article .articles .article {
      border-top: 1px solid  #eeefea ;
      padding-top: 40px;
      margin-bottom: 25px;
      /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
      /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
      /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
      @media (max-width: 719px) {
        /* line 317, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .article, .template-article .articles .article {
          border-top: none;
          margin: 0 0 30px;
          padding: 0; } }
      /* line 328, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .article.first, .template-article .articles .article.first {
        border-top: none;
        padding-top: 0; }
      /* line 335, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .article .article-content, .template-article .articles .article .article-content {
        float: right;
        width: 100%; }
        @media (max-width: 719px) {
          /* line 335, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
          .template-blog .articles .article .article-content, .template-article .articles .article .article-content {
            width: 100%; } }
        /* line 341, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .article .article-content .title, .template-article .articles .article .article-content .title {
          display: block; }
          @media (max-width: 719px) {
            /* line 341, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
            .template-blog .articles .article .article-content .title, .template-article .articles .article .article-content .title {
              display: block; } }
        /* line 347, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .article .article-content .rte img, .template-article .articles .article .article-content .rte img {
          width: 100%; }
      /* line 353, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .article .title, .template-article .articles .article .title {
        margin-top: -3px;
        margin-bottom: 30px; }
        /* line 356, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .article .title, .template-blog .articles .article .title a, .template-article .articles .article .title, .template-article .articles .article .title a {
          color: #666666;
          font-family:  'Source Sans Pro', sans-serif ;
          text-transform:   ;
          font-size: 28px;
          font-size:   ;
          font-weight: 700;
          line-height: 1.2; }
        /* line 366, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-blog .articles .article .title a:hover, .template-article .articles .article .title a:hover {
          color: #b3b3b3; }
  /* line 380, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-blog .articles .pagination, .template-article .articles .pagination {
    width: 75%;
    float: right;
    display: block; }
    @media (max-width: 719px) {
      /* line 380, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .articles .pagination, .template-article .articles .pagination {
        display: none; } }
  /* line 387, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-blog .mobile .pagination, .template-article .mobile .pagination {
    margin-bottom: 45px;
    padding-bottom: 0;
    display: none; }
    @media (max-width: 719px) {
      /* line 387, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-blog .mobile .pagination, .template-article .mobile .pagination {
        display: block; } }

/* Just the article template */
/* line 401, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
.template-article .articles {
  /* Comments */
  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
  /* line 402, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-article .articles .article {
    border-top: none;
    padding-top: 0px; }
  /* line 410, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
  .template-article .articles .comments-wrap {
    border-top: 1px solid #ffffff;
    padding-top: 40px;
    margin-bottom: 45px;
    /* List of comments */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* Submit a new comment */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */ }
    @media (min-width: 720px) {
      /* line 410, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap {
        width: 100%;
        float: right;
        margin-bottom: 20px; } }
    /* line 421, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.no-comments {
      margin-bottom: 35px; }
    /* line 423, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap .title {
      display: block !important;
      font-size: 14px;
      font-weight: 400;
      color:  #666666 ; }
      /* line 430, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap .title .count {
        color:  #757575 ; }
    @media (min-width: 720px) {
      /* line 438, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read {
        margin-top: 20px; } }
    /* line 441, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.read .comments {
      margin: 35px 0 25px; }
    /* line 445, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.read .comment {
      padding: 25px 0;
      border-top: 1px solid #eeefea; }
      /* line 449, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read .comment:first-child {
        padding: 0 0 25px;
        border-top: none; }
      /* line 454, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read .comment.last {
        padding: 25px 0 0; }
      /* line 456, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read .comment .date {
        display: block;
        font-size: 12px;
        color:  #afacac ;
        margin-bottom: 10px; }
        /* line 463, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-article .articles .comments-wrap.read .comment .date:hover {
          color:  #777777 ; }
      /* line 465, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read .comment .body p {
        font-size: 14px;
        line-height: 22px;
        font-style: italic;
        margin: 0 0 10px; }
      /* line 471, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.read .comment .author {
        font-family:  'Source Sans Pro', sans-serif ;
        font-size: 12px;
        color:  #757575 ;
        width: 100%;
        text-align: right;
        margin: 25px 0 0; }
    @media (max-width: 719px) {
      /* line 486, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit {
        margin-bottom: 45px; } }
    /* line 489, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.submit .error-message {
      margin: 25px 0;
      text-align: center; }
      /* line 493, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit .error-message span {
        margin-top: 12px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
    /* line 499, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.submit .title {
      margin-bottom: 35px; }
    /* line 503, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.submit .field-wrap {
      margin-top: 20px; }
      @media (min-width: 720px) {
        /* line 503, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-article .articles .comments-wrap.submit .field-wrap {
          width: 75%; } }
      /* line 510, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit .field-wrap:first-child {
        margin-top: 0; }
      /* line 512, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit .field-wrap label {
        cursor: auto;
        display: block;
        font-size: 14px;
        margin-bottom: 12px; }
      /* line 519, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit .field-wrap .field {
        width: 100%; }
      /* line 523, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
      .template-article .articles .comments-wrap.submit .field-wrap textarea {
        min-height: 210px; }
    /* line 529, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
    .template-article .articles .comments-wrap.submit .submit-button {
      margin-top: 30px;
      padding: 10px 14px 9px; }
      @media (max-width: 719px) {
        /* line 529, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_blog.scss */
        .template-article .articles .comments-wrap.submit .submit-button {
          width: 100%; } }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .showcase, #product-area .details, .quick-shop-content .showcase, .quick-shop-content .pager, .quick-shop-content .details {
  float: left; }

/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .showcase .container, #product-area .pager .container, .quick-shop-content .showcase .container, .quick-shop-content .pager .container {
  margin-bottom: 10px; }
  @media (max-width: 719px) {
    /* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .container, #product-area .pager .container, .quick-shop-content .showcase .container, .quick-shop-content .pager .container {
      margin-bottom: 0; } }
/* line 17, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .showcase .wrap, #product-area .pager .wrap, .quick-shop-content .showcase .wrap, .quick-shop-content .pager .wrap {
  position: relative;
  z-index: 100; }
  /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase .wrap .overlay, #product-area .pager .wrap .overlay, .quick-shop-content .showcase .wrap .overlay, .quick-shop-content .pager .wrap .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: box-shadow 200ms;
    -moz-transition: box-shadow 200ms;
    -o-transition: box-shadow 200ms;
    transition: box-shadow 200ms; }
    /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
    .lt-ie9 #product-area .showcase .wrap .overlay, .lt-ie9 #product-area .pager .wrap .overlay, .lt-ie9 .quick-shop-content .showcase .wrap .overlay, .lt-ie9 .quick-shop-content .pager .wrap .overlay {
      border: 1px solid  #ffffff ; }
  /* line 33, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase .wrap.active .overlay, #product-area .pager .wrap.active .overlay, .quick-shop-content .showcase .wrap.active .overlay, .quick-shop-content .pager .wrap.active .overlay {
    -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3); }
  /* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase .wrap img, #product-area .pager .wrap img,.quick-shop-content .showcase .wrap img {
    width: 100%;
    height: auto;
    display: block; }
#product-area .pager .wrap{margin:0 5px;}
#product-area .pager .wrap img{ max-width:100px;max-height:100px;  }
/* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .showcase, .quick-shop-content .showcase {
  width: 62%;
  padding: 0 15px 0 0; }
  /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase.wide, .quick-shop-content .showcase.wide {
    width: 62%; }
    @media (max-width: 719px) {
      /* line 44, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .showcase.wide, .quick-shop-content .showcase.wide {
        width: 100%; } }
  /* line 49, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase .container, .quick-shop-content .showcase .container {
    overflow: hidden;
    position: relative; }
    /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .container .spinner, .quick-shop-content .showcase .container .spinner {
      position: absolute !important;
      left: 50% !important;
      top: 50% !important;
      z-index: 50 !important; }
  @media (max-width: 719px) {
    /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase, .quick-shop-content .showcase {
      width: 100%;
      padding: 0; } }
  @media (max-width: 719px) {
    /* line 65, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes, .quick-shop-content .showcase .notes {
      display: none; } }
  /* line 68, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .showcase .notes a, .quick-shop-content .showcase .notes a {
    font-family:  'Source Sans Pro', sans-serif ;
    font-size: 12px;
    color:  #b0b0b0 ;
    position: relative;z-index:150;
    padding-left: 28px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
    /* line 76, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes a.email-us, #product-area .showcase .notes a.guides, #product-area .showcase .notes a.toggle-fullview, .quick-shop-content .showcase .notes a.email-us, .quick-shop-content .showcase .notes a.guides, .quick-shop-content .showcase .notes a.toggle-fullview {
      margin-top: 15px; }
    /* line 78, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes a .icon, .quick-shop-content .showcase .notes a .icon {
      display: block;
      font-family: 'icons';
      margin-right: 5px;
      font-size: 87px;
      line-height: 10px;
      text-indent: -17px;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      overflow: hidden;
      -webkit-font-smoothing: antialiased; }
    /* line 94, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes a.toggle-fullview .icon, .quick-shop-content .showcase .notes a.toggle-fullview .icon {
      font-size: 79px; }
    /* line 98, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes a.guides .icon, .quick-shop-content .showcase .notes a.guides .icon {
      font-size: 80px;
      text-indent: -14px; }
    /* line 103, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .showcase .notes a.email-us .icon, .quick-shop-content .showcase .notes a.email-us .icon {
      font-size: 86px; }
/* line 109, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */

#product-area .pager {
  width:60%;display:block;z-index:0;text-align:right;
  padding: 0; top:85%;left:0%;}
  @media (max-width: 719px) {
    #product-area .pager  /* fix */
    /* line 109, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .pager, .quick-shop-content .pager {
      width: 100%;
      padding: 0px 0 0;
      text-align: center; } }
  /* line 119, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .pager .wrap{ width:70px;display:block;float:right}  
.quick-shop-content .pager .wrap {
    margin-top: 10px; }
    /* line 121, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .pager .wrap.first, .quick-shop-content .pager .wrap.first {
      margin-top: 0px; }
    @media (max-width: 719px) {
      /* line 119, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
       .quick-shop-content .pager .wrap , #product-area .pager .wrap{
        display: -moz-inline-stack;
        display: inline-block;
  		
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
        width: 64px;
        margin: 0 8px 20px; }

}
  /* line 129, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .pager .overlay, .quick-shop-content .pager .overlay {
    cursor: pointer; }
/* line 134, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .details, .quick-shop-content .details {
  width: 38%;
  padding: 0 0 0 15px; }
  @media (max-width: 719px) {
    /* line 134, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details, .quick-shop-content .details {
      width: 100%;
      padding: 30px 0 0;
      margin-top: 10px; }
      /* line 143, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details.border, .quick-shop-content .details.border {
        } }
  /* line 148, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details .header, .quick-shop-content .details .header {
    padding-bottom: 0px; }
    @media (max-width: 719px) {
      /* line 148, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .header, .quick-shop-content .details .header {
        display: none; } }
    /* line 152, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .header .brand, .quick-shop-content .details .header .brand {
      font-size: 14px;
      margin: 0 0 0;
      color:  #757575 ; }
    /* line 157, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .header .title, .quick-shop-content .details .header .title {
      font-size: 18px;color:#797979;color:#797979 !important;font-weight:normal;font-weight:normal !important;
      margin: 0px 0;text-align:left; }
    /* line 161, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .header .price, .quick-shop-content .details .header .price {
      font-size: 18px;
      margin: 0 0 0; }
      /* line 165, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .header .price .original, .quick-shop-content .details .header .price .original {
        margin-right: 10px; }
  /* line 169, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details .share-buttons, .quick-shop-content .details .share-buttons {
    padding: 0px 0 20px; display:none;}
    @media (max-width: 719px) {
      /* line 169, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .share-buttons, .quick-shop-content .details .share-buttons {
        display: none; } }
  /* line 176, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details .options, .quick-shop-content .details .options, .quick-shop-content .details .description {
  border-top:1px solid #eeefea;}
  /* line 180, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details .options, .quick-shop-content .details .options {
    padding: 32px 0 12px; }
    /* line 183, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .options.unavailable, .quick-shop-content .details .options.unavailable {
      padding: 20px 0; }
    @media (max-width: 719px) {
      /* line 180, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .options, .quick-shop-content .details .options {
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 32px; } }
    /* line 193, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .options .field, .quick-shop-content .details .options .field {
      position: relative;
      left: -4px;
      width: 46px;
      text-align: center; }
    /* line 200, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .options .selector-wrapper, .quick-shop-content .details .options .selector-wrapper {
      margin-bottom: 20px; }
      @media (max-width: 719px) {
        /* line 204, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
        #product-area .details .options .quantity, .quick-shop-content .details .options .quantity {
          margin-bottom: 15px; }
        /* line 208, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
        #product-area .details .options .submit-wrapper, .quick-shop-content .details .options .submit-wrapper {
          margin-bottom: 15px; }
          /* line 210, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
          #product-area .details .options .selector-wrapper.submit-wrapper label, .quick-shop-content .details .options .submit-wrapper label {
            display: none; }
          /* line 211, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
          #product-area .details .options .selector-wrapper.submit-wrapper input, .quick-shop-content .details .options .submit-wrapper input {
            width: 100%; } }
      /* line 215, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .options  label, .quick-shop-content .details .options  label {
        font-size: 14px;
        color: #ababab;
        margin-right: 20px;
        text-align: right;
        max-width: 40%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
        @media (max-width: 719px) {
          /* line 215, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
          #product-area .details .options label, .quick-shop-content .details .options label {
            width: 100% !important;
            max-width: 100% !important;
            text-align: left;
            margin-right: 0;
            margin-bottom: 15px; } }
      /* line 232, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .options .single-option-selector, .quick-shop-content .details .options .single-option-selector {
        width: 100%; }
        @media (max-width: 719px) {
          /* line 232, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
          #product-area .details .options .single-option-selector, .quick-shop-content .details .options  .single-option-selector {
            width: 100% !important; } }
      /* line 239, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .options .pxuSexyDropWrapper, .quick-shop-content .details .options  .pxuSexyDropWrapper {
        width: 50%; }
        @media (max-width: 719px) {
          /* line 239, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
          #product-area .details .options .pxuSexyDropWrapper, .quick-shop-content .details .options .pxuSexyDropWrapper {
            width: 100% !important; } }
      /* line 246, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      #product-area .details .options  .submit, .quick-shop-content .details .options .selector-wrapper .submit {
        margin-top: 12px; }
    /* line 250, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .options .disabled, .quick-shop-content .details .options .disabled {
      background:  #ffffff ;
      cursor: default;
      margin-top: 12px; }
    /* line 256, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .options.unavailable .disabled, .quick-shop-content .details .options.unavailable .disabled {
      margin-top: 0; }
  /* line 262, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details .description, .quick-shop-content .details .description {
    padding-top: 20px; }
    /* line 265, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .description.no-border, .quick-shop-content .details .description.no-border {
      border-top: 0px; }
    /* line 269, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details .description .go-to-product, .quick-shop-content .details .description .go-to-product {
      font-size: 12px;
      color:  #b3b3b3 ; }
      /* line 274, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      .ie10 #product-area .details .description .go-to-product span, .ie10 .quick-shop-content .details .description .go-to-product span {
        font-weight: 600; }
      /* line 275, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      .lt-ie10 #product-area .details .description .go-to-product span, .lt-ie10 .quick-shop-content .details .description .go-to-product span {
        font-weight: 600; }
/* line 283, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
#product-area .details.no-options .options, .quick-shop-content .details.no-options .options {
  padding: 20px 0 12px; }
  /* line 285, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details.no-options .options .selector-wrapper label, .quick-shop-content .details.no-options .options .selector-wrapper label {
    display: none; }
@media (max-width: 719px) {
  /* line 282, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  #product-area .details.no-options, .quick-shop-content .details.no-options {
    margin: 0;
    padding: 0; }
    /* line 292, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    #product-area .details.no-options .options, .quick-shop-content .details.no-options .options {
      padding: 30px 0; } }

/* line 302, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
.template-product .mobile-product-title {
  display: none;
  text-align: center;
  margin-top: 40px; }
  @media (max-width: 719px) {
    /* line 302, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .template-product .mobile-product-title {
      display: block; } }
  /* line 308, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .mobile-product-title .brand {
    color:  #757575 ;
    font-size: 14px;
    margin: 0 0 0; }
  /* line 313, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .mobile-product-title .title {
    font-size: 35px;
    margin: 5px 0 0px; }
  /* line 317, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .mobile-product-title .price {
    font-size: 18px; }
  /* line 321, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .mobile-product-title .share-buttons {
    margin: 30px 0 0; }
/* line 326, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
.template-product #product-area {
  padding: 40px 0 60px; position:relative;}
  @media (max-width: 719px) {
    /* line 326, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .template-product #product-area {
      padding: 25px 0; } }
/* line 333, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
.template-product .related-products {
  margin-bottom: 80px; }
/* line 337, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
.template-product .share-wrap {
  height: 25px;
  margin: 0 12px 10px 0;
  text-align: left;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  *vertical-align: auto;
  zoom: 1;
  *display: inline; }
  /* line 343, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap > iframe, .template-product .share-wrap > div {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
  /* line 347, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.first {
    border-top: 1px solid ; }
  /* line 351, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.facebook {
    width: 90px; }
    /* line 353, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .template-product .share-wrap.facebook .fb_iframe_widget span {
      vertical-align: top; }
  /* line 357, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.twitter {
    width: 56px; }
  /* line 358, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.google {
    width: 32px; }
  /* line 359, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.pinterest {
    width: 39px; }
  /* line 360, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .template-product .share-wrap.fancy {
    width: 57px;
    height: 20px;
    overflow: hidden; }

/* line 368, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
.fullscreen-product-viewer {
  display: none;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/ie-product-overlay-bg.png?v=83967569880151450761462307375 ) repeat 50% 50%;
  background: rgba(0, 0, 0, 0.5); }
  /* line 383, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .modal {
    width: 72%;
    min-width: 690px;
    max-width: 1028px;
    margin: 0 auto;
    padding: 15px; }
  /* line 391, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .modal-wrap {
    position: relative;
    background-color: #ffffff;
    padding: 44px 30px;
    width: 100%; }
  /* line 398, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    color: #000000;
    cursor: pointer;
    line-height: 21px;
    text-indent: -5px;
    font-family: 'icons';
    font-size: 62px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3; }
    /* line 413, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .close:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
      opacity: 0.4; }
  /* line 417, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .showcase .wrap, .fullscreen-product-viewer .pager .wrap {
    position: relative}
    /* line 420, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase .wrap .overlay, .fullscreen-product-viewer .pager .wrap .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -webkit-transition: box-shadow 200ms;
      -moz-transition: box-shadow 200ms;
      -o-transition: box-shadow 200ms;
      transition: box-shadow 200ms; }
      /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_mixins.scss */
      .lt-ie9 .fullscreen-product-viewer .showcase .wrap .overlay, .lt-ie9 .fullscreen-product-viewer .pager .wrap .overlay {
        border: 1px solid  #ffffff ; }
    /* line 432, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase .wrap.active .overlay, .fullscreen-product-viewer .pager .wrap.active .overlay {
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3); }
    /* line 434, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase .wrap img, .fullscreen-product-viewer .pager .wrap img {
      width: 100%;
      height: auto; }
  /* line 438, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .showcase {
    width: 100%;
    padding: 0 90px 0 0; }
    /* line 442, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase.wide {
      padding: 0; }
    /* line 444, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase .container {
      position: relative; }
      /* line 446, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
      .fullscreen-product-viewer .showcase .container .spinner {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        z-index: 50 !important; }
    /* line 453, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .showcase .wrap {
      z-index: 100; }
  /* line 456, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
  .fullscreen-product-viewer .pager {
    position: absolute;
    
    width: 58px;top:45px;right:45px; }
    /* line 462, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .pager .wrap {
      margin-bottom: 20px; }
    /* line 463, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .pager .wrap img {
      display: block; }
    /* line 464, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_product.scss */
    .fullscreen-product-viewer .pager .overlay {
      cursor: pointer; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-page .text-content-wrap, .template-contact .text-content-wrap {
  padding-bottom: 80px;
  padding-top: 40px; }
  @media (max-width: 719px) {
    /* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
    .template-page .text-content-wrap, .template-contact .text-content-wrap {
      padding-top: 0;
      padding-bottom: 50px; } }
/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-page .text-content, .template-contact .text-content {
  float: left;
  width: 68.5714%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    /* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
    .template-page .text-content, .template-contact .text-content {
      width: 100%;
      float: none;
      padding-right: 0px; } }
/* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-page .sidebar, .template-contact .sidebar {
  float: right;
  width: 31.4286%;
  padding-left: 8.5714%; }
  @media (max-width: 719px) {
    /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
    .template-page .sidebar, .template-contact .sidebar {
      float: none;
      width: 100%;
      margin-top: 40px;
      padding-top: 30px;
      padding-left: 0px;
      border-top: 1px solid  #ffffff ; } }
  /* line 35, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-page .sidebar .title, .template-contact .sidebar .title {
    color:  #666666 ;
    font-size: 14px;
    margin-bottom: 30px; }

a.lb img{width:240px;}
a.lb{overflow:hidden;width:205px;height:185px;float:left;margin-bottom:10px;}
a.lb {margin:22px;}

/* line 47, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-contact .error-message {
  margin: 0 0 25px;
  text-align: center;
  width: 100%; }
  /* line 52, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-contact .error-message span {
    margin-top: 12px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline; }
/* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-contact .field-wrap {
  margin-top: 20px; }
  /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-contact .field-wrap.author {
    margin-top: 0; }
  /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-contact .field-wrap label {
    cursor: auto;
    display: block;
    font-size: 14px;
    margin-bottom: 12px; }
  /* line 69, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-contact .field-wrap .field {
    width: 100%; }
  /* line 73, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
  .template-contact .field-wrap textarea {
    min-height: 210px; }
/* line 79, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
.template-contact .submit-button {
  margin-top: 30px;
  padding: 10px 14px 9px; }
  @media (max-width: 719px) {
    /* line 79, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_page.scss */
    .template-contact .submit-button {
      width: 100%; } }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
.template-collection .content {
  padding-bottom: 65px; }
  @media (max-width: 719px) {
    /* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .content {
      padding-bottom: 50px; } }
/* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
.template-collection .page-title {
  position: relative;
  white-space: nowrap;
  /* 
   
  */ }
  /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .page-title .label {
    position: relative;text-transform:uppercase;
  color:  #c9c7c7 ;font-weight:normal;
    top: 8px; }
  /* line 23, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .page-title .tags-wrap {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    margin-top: 18px; }
    /* line 27, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .page-title .tags-wrap.preload {
      position: absolute;
      left: -99999px; }
.template-collection .price{display:none;}
.template-collection .brand{display:none;}
    @media (max-width: 719px) {
      /* line 23, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .tags-wrap {
        margin-top: 30px; } }
  /* line 34, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .page-title .pxuSexyDropWrapper {
    display: none;
    position: absolute !important;
    right: 0;
    top: 25px; }
    /* line 40, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .page-title .pxuSexyDropWrapper.show {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
    @media (max-width: 719px) {
      /* line 34, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .pxuSexyDropWrapper {
        display: block !important;
        position: relative !important;
        right: auto;
        top: auto; } }
    /* line 49, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .page-title .pxuSexyDropWrapper select {
      top: 4px !important; }
  /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .page-title .tags {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    position: relative;
    padding-left: 16px;
    margin-left: 28px; }
    /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .page-title .tags:before {
      content: '';
      position: absolute;
      left: 0;
      top: -12px;
      bottom: -8px;
      width: 1px;
      background: #eeefea; }
    @media (max-width: 719px) {
      /* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .tags {
        display: none !important; } }
    /* line 74, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .page-title .tags .tag {
      display: none;
      font-size: 14px;
      margin: 0 12px; }
      /* line 78, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .tags .tag.show {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
      /* line 80, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .tags .tag a {
        color:  #666666 ; }
      /* line 81, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .page-title .tags .tag a:hover {
        color:  #b3b3b3 ; }
/* line 88, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
.template-collection .collection-header .description {
  float: left;
  width: 60%;
  padding-right: 30px;
  margin-top: 50px; }
  @media (max-width: 719px) {
    /* line 88, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .collection-header .description {
      float: none;
      width: 100%;
      margin-top: 0px;
      padding-right: 0px; } }
/* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
.template-collection .collection-header .pagination {
  float: right;
  width: 40%;
  padding: 50px 0 0;
  text-align: right; }
  @media (max-width: 719px) {
    /* line 102, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .collection-header .pagination {
      display: none; } }
/* line 112, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
.template-collection .product-list {
  padding: 50px 0 0; margin:0;}
.template-collection .product-list .title{margin-top:20px;margin-top:20px !important;font-size:18px; font-weight:normal;color:#797979}
.template-collection .description{font-size:13px;color:#797979}
@media (max-width: 719px) {
    /* line 112, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
    .template-collection .product-list {
      padding: 10px 0 0; } }
  /* line 116, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .product-list .product {
    margin-bottom: 15px; }
    @media (max-width: 719px) {
      /* line 116, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
      .template-collection .product-list .product {
        margin-bottom: 15px; } }
  /* line 121, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .product-list.two .product {
    width: 50%; }
  /* line 122, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .product-list.three .product {
    width: 33.3%; }
  /* line 123, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_collection.scss */
  .template-collection .product-list.four .product {
    width: 25%; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
.template-cart .content {
  padding-bottom: 65px; }
/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
.template-cart .table {
  width: 100%;
  margin: 40px 0 30px;
  border: 1px solid  #ffffff ; }
  @media (max-width: 719px) {
    /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table {
      margin-top: 0px; } }
  /* line 14, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
  .template-cart .table th {
    background: #f9f9f9;
    padding: 22px 0;
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
    /* line 21, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table th.first {
      padding: 22px 0px 22px 30px; }
    @media (max-width: 719px) {
      /* line 14, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table th {
        display: none; } }
  @media (max-width: 719px) {
    /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table, .template-cart .table tr, .template-cart .table td, .template-cart .table tbody {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left; } }
  /* line 38, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
  .template-cart .table td {
    padding: 32px 30px 32px 0;
    border-top: 1px solid  #ffffff ;
    vertical-align: middle;
    position: relative; }
    @media (max-width: 719px) {
      /* line 38, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td {
        display: block;
        border-top: none;
        width: 100% !important;
        padding: 12px 12px 12px 30px !important; } }
    /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.first {
      padding: 32px 30px; }
      @media (max-width: 719px) {
        /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.first {
          padding-top: 30px !important;
          border-top: 1px solid  #ffffff ; } }
    /* line 59, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.product-item {
      width: 42.8%; }
      /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.product-item .image-wrap {
        width: 25%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
        /* line 66, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.product-item .image-wrap .image {
          width: 100%;
          position: relative;
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: middle;
          *vertical-align: auto;
          zoom: 1;
          *display: inline; }
          /* line 71, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.product-item .image-wrap .image .outline {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid rgba(0, 0, 0, 0.1); }
          /* line 79, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.product-item .image-wrap .image img {
            width: 100%; }
        @media (max-width: 719px) {
          /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.product-item .image-wrap {
            vertical-align: top; } }
      /* line 84, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.product-item .remove {
        display: none;
        width: 12px;
        height: 11px;
        position: absolute;
        top: 30px;
        right: 20px;
        z-index: 1000;
        font-family: 'icons';
        color:  #eeefea ;
        line-height: 10px;
        text-indent: -8px;
        font-size: 47px; }
        /* line 98, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.product-item .remove:hover {
          color:  #ffffff ; }
        @media (max-width: 719px) {
          /* line 84, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.product-item .remove {
            display: -moz-inline-stack;
            display: inline-block;
            vertical-align: middle;
            *vertical-align: auto;
            zoom: 1;
            *display: inline; } }
      /* line 103, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.product-item .wrap {
        width: 75%;
        padding-left: 20px;
        padding-right: 35px;
        position: relative;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline; }
      /* line 111, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.product-item .label {
        display: block; }
        /* line 114, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.product-item .label.vendor {
          color:  #757575 ;
          font-size: 10px;display:none;
          margin-bottom: 10px; }
        /* line 120, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.product-item .label.title {
          margin-bottom: 15px; }
          /* line 122, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.product-item .label.title a {
            color: #b3b3b3;
            font-size: 16px; }
        /* line 127, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.product-item .label.variant {
          font-family:  'Source Sans Pro', sans-serif ;
          font-size: 12px; }
    /* line 135, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.price {
      width: 17.2%;
      font-size: 14px; }
      /* line 138, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.price:before {
        content: "Price"; }
    /* line 141, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.quantity {
      width: 17.2%; }
      /* line 144, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.quantity:before {
        content: 'Quantity';
        margin-top: 2px; }
      /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.quantity .field {
        width: 56px;
        text-align: center;
        color:  #757575 ; }
        @media (max-width: 719px) {
          /* line 149, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .table td.quantity .field {
            padding: 3px 0;
            width: 25px; } }
    /* line 161, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.total {
      width: 17.2%; }
      /* line 163, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.total:before {
        content: "Total"; }
      @media (max-width: 719px) {
        /* line 161, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.total {
          padding-bottom: 30px !important; } }
    /* line 168, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.remove {
      width: 5.6%; }
      @media (max-width: 719px) {
        /* line 168, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.remove {
          display: none; } }
      /* line 172, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .table td.remove a {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
        position: relative;
        top: -2px;
        width: 12px;
        height: 11px;
        font-family: 'icons';
        color:  #eeefea ;
        line-height: 10px;
        text-indent: -8px;
        font-size: 47px; }
        /* line 184, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td.remove a:hover {
          color:  #ffffff ; }
    /* line 188, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td.total {
      width: 17.2%;
      font-size: 14px;
      color:  #666666 ; }
    /* line 194, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .table td:before {
      display: none;
      width: 25%;
      margin-right: 30px;
      font-size: 14px;
      color:  #757575 ;
      text-align: right; }
      @media (max-width: 719px) {
        /* line 194, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .table td:before {
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: top;
          *vertical-align: auto;
          zoom: 1;
          *display: inline; } }
/* line 209, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
.template-cart .cart-tools {
  padding-bottom: 40px; }
  /* line 212, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
  .template-cart .cart-tools .instructions {
    float: left;
    width: 48.5%; }
    @media (max-width: 719px) {
      /* line 216, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .cart-tools .instructions.standard {
        display: none; } }
    /* line 219, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .instructions.mobile {
      display: none; }
      @media (max-width: 719px) {
        /* line 219, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
        .template-cart .cart-tools .instructions.mobile {
          display: block;
          width: 100%;
          margin-top: 35px; } }
    /* line 228, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .instructions p {
      margin: 0 0 15px; }
    /* line 232, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .instructions .field {
      width: 100%;
      min-height: 86px; }
  /* line 238, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
  .template-cart .cart-tools .totals {
    float: right;
    width: 240px; }
    @media (max-width: 719px) {
      /* line 238, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .cart-tools .totals {
        width: 100%; } }
    /* line 243, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .totals .price {
      font-size: 28px;
      color:  #666666 ;
      margin: 0; }
    /* line 249, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .totals .message {
      font-size: 14px;
      font-style: italic;
      color:  #757575 ;
      margin: 10px 0 25px; }
    /* line 255, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
    .template-cart .cart-tools .totals .checkout {
      /* width: 100%;padding-top: 20px;*/
		font-size: 14px;
	background-color: #d7ca9d;
	padding: 9px 14px;
      border-radius: 4px;
      width: 135px;
       }
.template-cart .cart-tools .totals .checkout:hover {
	background-color: #000;
       }
.template-cart .cart-tools .totals .checkout input {
  font-family: 'PT Serif', 'Times New Roman', Times, serif;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
}
      /* line 260, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
      .template-cart .cart-tools .totals .checkout .action-button {
         }
        @media (max-width: 719px) {
          /* line 260, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_cart.scss */
          .template-cart .cart-tools .totals .checkout .action-button {
            width: 100%;
            text-align: center; } }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .results {
  width: 66%;
  padding-bottom: 50px; }
  @media (max-width: 719px) {
    /* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .results {
      width: 100%; } }
/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .results-label {
  font-size: 14px;
  font-style: italic;
  margin: 40px 0; }
  @media (max-width: 719px) {
    /* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .results-label {
      margin: 0 0 40px;
      text-align: center; } }
/* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .result {
  display: block;
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid #eeefea; }
  /* line 26, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.first {
    padding-top: 0 !important;
    border-top: 0; }
/* line 29, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .result.item-article {
  padding: 30px 0 10px; }
  /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-article .title {
    font-weight: 700;
    font-size: 16px;
    color:  #666666 ;
    margin: 0 0 12px; }
  /* line 39, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-article .date {
    margin-bottom: 20px;
    color:  #757575 ;
    font-size: 12px; }
/* line 50, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .result.item-product figure, .template-search .result.item-product .details {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  *vertical-align: auto;
  zoom: 1;
  *display: inline; }
/* line 54, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .result.item-product figure {
  position: relative;
  width: 32%; }
  /* line 58, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-product figure img {
    width: 100%; }
  /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-product figure a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000; }
    /* line 70, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .result.item-product figure a.overlay {
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
/* line 76, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .result.item-product .details {
  width: 68%;
  padding-left: 30px; }
  /* line 80, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-product .details .brand {
    font-size: 10px;
    margin: 5px 0 0; }
    /* line 85, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .result.item-product .details .brand a {
      color:  #757575 ; }
  /* line 87, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-product .details .title {
    font-size: 16px;
    color: #b3b3b3;
    margin: 14px 0 0; }
    /* line 92, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .result.item-product .details .title a {
      color: #b3b3b3; }
  /* line 94, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
  .template-search .result.item-product .details .price {
    font-size: 14px;
    color: ;
    margin: 16px 0 0; }
    /* line 99, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .result.item-product .details .price .label {
      color:  #666666 ;
      margin-right: 5px; }
    /* line 103, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
    .template-search .result.item-product .details .price .original {
      text-decoration: line-through;
      margin-right: 2px; }
/* line 112, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_search.scss */
.template-search .pagination {
  padding-bottom: 40px; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_404.scss */
.template-404 .content {
  padding-bottom: 60px; }
/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_404.scss */
.template-404 .message {
  width: 50%;
  margin: 40px 0; }
  @media (max-width: 719px) {
    /* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/templates/_404.scss */
    .template-404 .message {
      width: 100%;
      margin-top: 0px;
      text-align: center; } }

/* Footer */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
.main-footer {
  padding: 50px 15px; }
  /* line 4, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
  .main-footer .connect {
    display: block;
    text-align: center;
    border-top: 1px solid  #ffffff ;
    border-bottom: 1px solid  #eeefea ;
    /* -------------------------- */
    /* -------------------------- */
    /* -------------------------- */
    /* -------------------------- */
    /* -------------------------- */ }
    /* line 12, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect > li {
      width: 33.3333%;
      height: 185px;
      text-align: center;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
      /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect > li.first {
        border-left: none; }
      @media (max-width: 719px) {
        /* line 12, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .connect > li {
          width: 100% !important;
          height: auto;
          border-left: none;
          border-top: 1px solid  #ffffff ;
          padding: 0 0 45px; }
          /* line 28, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
          .main-footer .connect > li.first {
            border-top: none; } }
    /* line 32, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect.module-count-1 > li, .main-footer .connect.module-count-2 > li {
      width: 50%; }
    /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .title {
      margin: 40px 0 0;
      font-size: 14px;
      color:  #757575 ; }
      @media (max-width: 719px) {
        /* line 36, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .connect .title {
          margin-top: 45px; } }
    /* line 51, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .contact .title {
      margin-bottom: 35px; }
    /* line 55, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .contact .phone, .main-footer .connect .contact .email {
      color:  #757575 ;
      font-size: 14px;
      margin: 4px 0; }
      /* line 60, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .contact .phone a, .main-footer .connect .contact .email a {
        color:  #afacac ; }
        /* line 62, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .connect .contact .phone a:hover, .main-footer .connect .contact .email a:hover {
          color:  #777777 ; }
    /* line 72, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .social .title {
      margin-bottom: 35px; }
    /* line 76, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .social > ul {
      padding: 0 20px; }
    /* line 80, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .social .social-link {
      width: 26px;
      height: 26px;
      margin: 0 8px 6px;
      position: relative;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline; }
      /* line 88, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link a {
        font-family: 'icons';
        -webkit-font-smoothing: antialiased;
        line-height: 0px;
        font-size: 43px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        color:  #afacac ; }
        /* line 100, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .connect .social .social-link a:hover {
          color:  #777777 ; }
      /* line 103, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.twitter a {
        top: 11px;
        font-size: 48px; }
      /* line 104, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.facebook a {
        top: 12px; }
      /* line 105, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.pinterest a {
        top: 12px; }
      /* line 106, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.google-plus a {
        top: 14px;
        font-size: 40px; }
      /* line 107, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.instagram a {
        top: 12px;
        font-size: 40px; }
      /* line 108, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.tumblr a {
        top: 12px;
        font-size: 45px; }
      /* line 109, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.youtube a {
        top: 11px; }
      /* line 110, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .social .social-link.vimeo a {
        top: 12px; }
    /* line 119, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .newsletter .title {
      margin-bottom: 36px; }
    /* line 123, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .newsletter .wrap {
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      zoom: 1;
      *display: inline;
      width: 75%;
      padding-right: 65px;
      margin: 0 auto;
      border: 1px solid  #ffffff ;
      position: relative; }
      @media (max-width: 719px) {
        /* line 123, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .connect .newsletter .wrap {
          width: 210px; } }
    /* line 134, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .newsletter .email, .main-footer .connect .newsletter .submit {
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      font-family:  'Source Sans Pro', sans-serif ; }
    /* line 142, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .newsletter .email {
      padding: 8px 0 8px 8px;
      width: 100%;
      background: transparent;
      font-size: 12px;
      font-family:  'Source Sans Pro', sans-serif ;
      color:  #757575 ; }
    /* line 151, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .connect .newsletter .submit {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      width: 65px;
      background:  #afacac ;
      font-size: 10px;
      letter-spacing: 0.1em;
      color: #ffffff;
      text-transform: uppercase;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      -o-border-radius: 0px;
      border-radius: 0px; }
      /* line 165, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .connect .newsletter .submit:hover {
        background:  #777777 ; }
  /* line 172, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
  .main-footer .sub-footer {
    margin: 40px 0;
 }
    @media (max-width: 719px) {
      /* line 172, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer {
        text-align: center; } }
    /* line 177, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .sub-footer p {
      font-family:  'Source Sans Pro', sans-serif ;
      font-size: 12px;
      color:  #bbbbbb ;
      text-align: center;
      margin: 50px 0 30px; }
      @media (max-width: 719px) {
        /* line 177, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer p {
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: middle;
          *vertical-align: auto;
          zoom: 1;
          *display: inline;
          margin: 10px 0 15px; } }
      /* line 189, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer p a {
        color:  #b3b3b3 ; }
      /* line 190, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer p a:hover {
        color:  #999999 ; }
    /* line 193, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
    .main-footer .sub-footer .payment-options {
      margin: 10px 0 50px;
      display: block;
      text-align: center; }
      /* line 198, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options li {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
        height: 40px;
        margin: 0 4px;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=13);
        opacity: 0.13;
        background: url( //cdn.shopify.com/s/files/1/1247/5491/t/5/assets/main-sprite.png?v=93678888592050608781462307376 ) -18px -18px #ffffff; }
        /* line 205, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.diners-club {
          width: 100px;
          background-position: -21px  -218px; }
        /* line 206, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.maestro {
          width: 38px;
          background-position: -134px -218px; }
        /* line 207, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.interac {
          width: 31px;
          background-position: -185px -218px; }
        /* line 208, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.visa {
          width: 46px;
          background-position: -230px -218px; }
        /* line 209, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.mastercard {
          width: 38px;
          background-position: -291px -218px; }
        /* line 210, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.discover {
          width: 90px;
          background-position: -343px -218px; }
        /* line 211, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.dk {
          width: 41px;
          background-position: -446px -218px; }
        /* line 212, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.american-express {
          width: 60px;
          background-position: -501px -218px; }
        /* line 213, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.google-wallet {
          width: 81px;
          background-position: -575px -218px; }
        /* line 214, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.paypal {
          width: 53px;
          background-position: -673px -218px; }
        /* line 215, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.jcb {
          width: 50px;
          background-position: -742px -218px; }
        /* line 216, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.cirrus {
          width: 38px;
          background-position: -806px -218px; }
        /* line 217, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
        .main-footer .sub-footer .payment-options li.stripe {
          width: 49px;
          background-position: -858px -218px; }
      /* line 221, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light li {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
        opacity: 0.2; }
      /* line 223, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .diners-club {
        background-position: -21px  -168px; }
      /* line 224, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .maestro {
        background-position: -134px -168px; }
      /* line 225, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .interac {
        background-position: -185px -168px; }
      /* line 226, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .visa {
        background-position: -230px -168px; }
      /* line 227, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .mastercard {
        background-position: -291px -168px; }
      /* line 228, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .discover {
        background-position: -343px -168px; }
      /* line 229, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .dk {
        background-position: -446px -168px; }
      /* line 230, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .american-express {
        background-position: -501px -168px; }
      /* line 231, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .google-wallet {
        background-position: -575px -168px; }
      /* line 232, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .paypal {
        background-position: -673px -168px; }
      /* line 233, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .jcb {
        background-position: -742px -168px; }
      /* line 234, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .cirrus {
        background-position: -806px -168px; }
      /* line 235, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_footer.scss */
      .main-footer .sub-footer .payment-options.light .stripe {
        background-position: -858px -168px; }

/* ----------------------------- */
/* line 1, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }

/* line 2, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.ir br {
  display: none; }

/* line 3, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.hidden {
  display: none !important;
  visibility: hidden; }

/* line 4, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/* line 5, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/* line 6, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.invisible {
  visibility: hidden; }

/* line 7, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

/* line 8, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.clearfix:after {
  clear: both; }

/* line 9, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
.clearfix {
  *zoom: 1; }

@media print {
  /* line 12, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }

  /* line 13, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  a, a:visited {
    text-decoration: underline; }

  /* line 14, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  a[href]:after {
    content: " (" attr(href) ")"; }

  /* line 15, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  abbr[title]:after {
    content: " (" attr(title) ")"; }

  /* line 16, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }

  /* line 17, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }

  /* line 18, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  thead {
    display: table-header-group; }

  /* line 19, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  tr, img {
    page-break-inside: avoid; }

  /* line 20, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  img {
    max-width: 100% !important; }

  @page {
    margin: 0.5cm; }

  /* line 22, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  p, h2, h3 {
    orphans: 3;
    widows: 3; }

  /* line 23, /Users/jarednorman/Codes/pxu/shopify/atlantic/source/assets/styles/common/_normalize-bottom.scss */
  h2, h3 {
    page-break-after: avoid; } }

.main-header-wrap .main-header .store-title img {max-width: 300px; height: auto;}
 #product-area .details .options .quantity, .quick-shop-content .details .options .quantity {
          display: none; }


          